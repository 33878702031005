import {createSelectorFromKey} from '@shared/utilities/selectors';

const getRoot = (state) => state.popups;
const createRootSelector = (key) => createSelectorFromKey(getRoot, key);

export const getPopupComponent = createRootSelector('component');
export const getPopupOptions = createRootSelector('options');
export const getFullScreenPopupComponent = createRootSelector(
  'fullScreenComponent',
);
export const getFullScreenPopupOptions =
  createRootSelector('fullScreenOptions');
