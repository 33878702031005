import React from 'react'

import {post, put, authenticationHeader} from '@shared/services/api';
import urls from '@shared/app/urls';
import {resetState} from '@shared/utilities/actions';
import {openPopup} from '@shared/popups/actions';
import {getLoginCredentials} from './selectors';
import UnauthorizedPopup from './popups/UnauthorizedPopup';

export const REQUEST_UNAUTHORIZED = 'REQUEST_UNAUTHORIZED';
export const AUTHENTICATING = 'AUTHENTICATING';
export const LOGGED_IN = 'LOGGED_IN';
export const SET_HIDE_WELCOME_MESSAGE = 'SET_HIDE_WELCOME_MESSAGE';
export const CURRENT_MEMBER_ONLINE = "CURRENT_MEMBER_ONLINE";
export const CURRENT_MEMBER_OFFLINE = "CURRENT_MEMBER_OFFLINE";
export const STRIPE_OPTIONS_RECEIVED = 'STRIPE_OPTIONS_RECEIVED';

export const userLoggedIn = session => ({
  type: LOGGED_IN,
  currentMember: session,
});

export const startLogin = credentials => ({
  type: AUTHENTICATING,
  credentials,
});

export const requestUnauthorized = ({reason, options, responseJson}) => ({
  type: 'REQUEST_UNAUTHORIZED',
  reason,
  options,
  responseJson
});

export const hideWelcomeMessageSet = () => ({
  type: SET_HIDE_WELCOME_MESSAGE,
});

let   lastKeepAlive    = Date.now();
const rateLimitSeconds = 60;
const rateLimitMillis  = rateLimitSeconds * 1000;

// keep the session alive by pinging the server
export const sessionKeepAlive = ({rateLimit = false}= {}) => async (dispatch) => {
  if (rateLimit && (Date.now() - lastKeepAlive < rateLimitMillis))
    return Promise.resolve();

  lastKeepAlive = Date.now();

  // do this async and ignore any results
  put(urls.api.sessionKeepAlive(), {}, {
    authorizing: true,
  });

  return Promise.resolve();
};

export const handleUnauthorizedRequest = ({reason, options, responseJson}) => async (dispatch) => {
  dispatch(resetState());
  dispatch(openPopup(<UnauthorizedPopup reason={reason} />));
  dispatch(requestUnauthorized({reason, options, responseJson}));

  return Promise.resolve();
};

const getAuthHeader = (state) => {
  const {email, password} = getLoginCredentials(state);
  return authenticationHeader(email, password);
}

const resendOtpCode = ({state}) => put(
  urls.api.resendOtp(),
  {otp_type: 'sms'},
  {
    headers:     getAuthHeader(state),
    authorizing: true,
  },
);

const sendPhoneNumberCall = ({phone_number, state}) => put(
  urls.api.setupOtp(),
  {phone_number, otp_type: 'sms'},
  {
    headers:     getAuthHeader(state),
    authorizing: true,
  },
);

const sendLoginCall = ({email, password, state}) => {
  const authHeader = (email && password)
                    ? authenticationHeader(email, password)
                    : getAuthHeader(state);


  return post(urls.api.login(), {}, {
    headers:     authHeader,
    authorizing: true,
  });
}

export const login = (email, password) => async (dispatch) => {
  dispatch(startLogin({email, password}));
  const session = await sendLoginCall({email, password});
  dispatch(userLoggedIn(session));
  return session;
};

export const sendOtpCode = (otp_code, phoneVerified, otp_type = 'sms') => async (dispatch, getState) => {
  const headers = getAuthHeader(getState());
  try {
    let session;
    if (!phoneVerified) {
      session = await put(
        urls.api.setupOtp(),
        {otp_code, otp_type},
        {
          headers,
          authorizing: true,
        },
      );
    } else {
      session = await post(
        urls.api.login(),
        {code: otp_code},
        {
          headers,
          authorizing: true,
        },
      );
    }
    dispatch(userLoggedIn(session));
    return Promise.resolve(session);
  } catch(err) {
    return Promise.reject(err);
  }
};

export const startAuthenticatorAppFlow = () => async (dispatch, getState) => {
  const headers = getAuthHeader(getState());
  try {
    const session = await put(
      urls.api.setupOtp(),
      {otp_type: 'totp'},
      {
        headers,
        authorizing: true,
      }
    );
    return Promise.resolve(session);
  } catch(err) {
    return Promise.reject(err);
  }
}

export const sendOtpPhoneNumber = (phone_number) => async (dispatch, getState) => {
  try {
    const session = await sendPhoneNumberCall({phone_number, state: getState()});
    return Promise.resolve(session);
  } catch(err) {
    return Promise.reject(err);
  }
};

export const resendCode = (verified, phone_number) => async (dispatch, getState) => {
  const state = getState();
  try {
    let session;
    if (verified) {
      session = await resendOtpCode({state});
    } else if (phone_number) {
      session = await sendPhoneNumberCall({phone_number, state});
    } else {
      session = await sendLoginCall({state});
    }
    return Promise.resolve(session);
  } catch(err) {
    return Promise.reject(err);
  }
};

export const setHideWelcomeMessage = () => async dispatch => {
  try {
    const data = put(urls.api.config(), {hide_welcome_message: true});
    dispatch(hideWelcomeMessageSet());
    return data;
  } catch(err) {
    return Promise.reject(err);
  }
}

export const requestResetPassword = (email) => async (dispatch) => {
  try {
    const data = await post(urls.api.requestResetPassword(),  {
      user: {login: email}
    });
    return Promise.resolve(data);
  } catch(err) {
    return Promise.reject(err);
  }
}

export const resetPassword = (password, reset_password_token) => async (dispatch) => {
  try {
    const data = await put(urls.api.requestResetPassword(), {
      user: {
        password,
        password_confirmation: password,
        reset_password_token,
      }
    },{
      authorizing: true,
    });
    return Promise.resolve(data);
  } catch(err) {
    return Promise.reject(err);
  }
}

export const currentMemberOnline = () => ({
  type: CURRENT_MEMBER_ONLINE,
});

export const currentMemberOffline = () => ({
  type: CURRENT_MEMBER_OFFLINE,
});

export const stripeOptionsReceived = (setupIntent) => ({
  type: STRIPE_OPTIONS_RECEIVED,
  setupIntent,
});
