import { combineReducers } from 'redux';
import railsContext from '@shared/reducers/rails_context';
import registration from '@shared/registration/reducers';
import session from '@shared/session/reducers';
import popups from '@shared/popups/reducers';
import skills from '@shared/skills/reducers';
import technologies from '@shared/technologies/reducers';

export default combineReducers({
  railsContext,
  registration,
  session,
  popups,
  skills,
  technologies,
});
