import React, {useState, useRef, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {throttle} from 'underscore';
import classNames from 'classnames';

import styles from './scrollable.module.sass';

const scrollable = (WrappedComponent) => ({
  onLazyLoad,
  lazyLoadMargin = '120px',
  scrollMargin = 0,
  ...wrappedComponentProps
}) => {
  const [atContentTop, setAtContentTop] = useState(true);
  const [atContentBottom, setAtContentBottom] = useState(true);

  const bodyContentRef = useRef();
  const bodyRef = useRef();
  const flagRef = useRef();

  const updateShadows = () => {
    const {scrollHeight} = bodyContentRef.current;
    const {scrollTop, offsetHeight} = bodyRef.current;
    setAtContentTop(scrollTop <= scrollMargin);
    setAtContentBottom(offsetHeight + scrollTop + scrollMargin >= scrollHeight);
  };

  const onScrollHandler = useCallback(throttle(updateShadows, 50));

  const onIntersectionChanged = useCallback((entries, observer) => {
    if (entries[0].isIntersecting) onLazyLoad && onLazyLoad();
  });

  useEffect(() => {
    updateShadows();

    if (!onLazyLoad) return;

    const observer = new IntersectionObserver(onIntersectionChanged, {
      root: bodyRef.current,
      rootMargin: lazyLoadMargin,
      threshold: 0.5,
    });
    observer.observe(flagRef.current);

    return () => observer.disconnect();
  });

  const outerClasses = classNames(
    styles.bodyWrapper,
    !atContentTop && styles.scrolledBodyTop,
    !atContentBottom && styles.scrolledBodyBottom,
  );
  return (
    <div className={outerClasses}>
      <div className={styles.body} ref={bodyRef} onScroll={onScrollHandler}>
        <div ref={bodyContentRef}>
          <WrappedComponent {...wrappedComponentProps} />
          {onLazyLoad && <div ref={flagRef} className={styles.flagDiv}></div>}
        </div>
      </div>
    </div>
  );
};

scrollable.propTypes = {
  scrollMargin: PropTypes.number,
  onLazyLoad: PropTypes.func,
  lazyLoadMargin: PropTypes.string,
};

export default scrollable;
