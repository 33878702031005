import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';

import Input from '@shared/components/Input';
import ProfileImage from '@shared/components/ProfileImage';
import Text from '@shared/components/Text';
import ActionButton from '@shared/components/ActionButton';
import Card from '@shared/components/Card';
import {withTranslator} from '@shared/components/wrappers';
import {post, put} from '@shared/services/api';
import {setHideWelcomeMessage} from '@shared/session/actions';
import {openPopup} from '@shared/popups/actions';
import {fetchOnboardingSkills} from '@shared/skills/actions';
import {fetchTechnologies} from '@shared/technologies/actions';
import {getCurrentMember} from '@shared/session/selectors';
import {getTechnologies} from '@shared/technologies/selectors';
import {getOnboardingSkills} from '@shared/skills/selectors';

import {uploadFile} from '@shared/services/file_upload';

import Title from '../components/Title';
import urls from '@shared/app/urls';
import styles from './Onboarding.module.sass';

validate.options ||= {}
validate.options.fullMessages = false;

class Onboarding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastName: '',
      firstName: '',
      jobTitle: '',
      profileImage: null,
      skills: [],
      preferredTechnologies: [],
      errors: [],
      stepIndex: 0,
    };
  }

  steps = [{
    id: 'profile',
    renderFn: 'renderProfile',
    buttonCb: 'updateProfile',
    formReady: 'isProfileReady',
  },
  {
    id: 'skills',
    renderFn: 'renderSkills',
    buttonCb: 'updateSkills',
    formReady: 'isSkillsReady',
  },
  {
    id: 'technologies',
    renderFn: 'renderTechnologies',
    buttonCb: 'updateTechnologies',
    formReady: 'isTechnologiesReady',
  }];

  constraints = {
    email: {
      presence: {
        allowEmpty: false,
      },
      email: {
        message: (a,b,c,d, opts) => opts.i18n.t('auth.validations.email')
      },
    },
    password: {
      presence: {
        allowEmpty: false,
      },
    },
  }

  componentDidMount() {
    const {
      currentMember,
      fetchOnboardingSkills,
      fetchTechnologies,
    } = this.props;

    fetchOnboardingSkills();
    fetchTechnologies();

    this.setState({
      firstName: currentMember?.full_name?.split(" ")[0] ?? '',
      lastName: currentMember?.full_name?.split(" ")[1] ?? '',
      phoneNumber: currentMember.phone_number,
      jobTitle: currentMember.title,
      skills: currentMember.skills,
      preferredTechnologies: currentMember.preferred_technologies,
    });
  }

  onInputChange = (value, event) => {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onImageChange = (profileImage) => {
    this.setState({
      profileImage,
    });
  }

  validateForm() {
    const {email, password} = this.state;
    const validation = validate({email, password}, this.constraints);
    return validation;
  }

  handleValidationError(validationError) {
    const errors = Object.values(validationError).flat();
    this.setState({errors});
  }

  clearError = () => {
    this.setState({
      errors: [],
    });
  }

  updateProfile = async () => {
    const {firstName, lastName, phoneNumber, jobTitle, profileImage} = this.state;
    try {
      if (profileImage) {
        await uploadFile(
          urls.api.profile(),
          profileImage,
          {
            method: 'PUT',
            name: 'member[uploaded_avatar]',
          }
        );
      }

      await put(urls.api.profile(), {
        member: {
          full_name: `${firstName} ${lastName}`,
          phone_number: phoneNumber,
          title: jobTitle,
        },
      });
      this.goToNextStep();
    } catch(err) {
      console.log('error updating profile', err);
    }
  }

  updateSkills = async () => {
    const skill_ids = this.state.skills.map(skill => skill.id);
    try {
      await put(urls.api.profile(), {
        member: {
          skill_ids,
        },
      });
      this.goToNextStep();
    } catch(err) {
      console.log('error updating skills', err);
    }
  }

  updateTechnologies = async () => {
    const preferred_technology_ids = this.state.preferredTechnologies.map(tech => tech.id);
    try {
      await post(urls.api.technologies(), {
        member: {
          preferred_technology_ids,
        }
      });
      this.goToNextStep();
    } catch(err) {
      console.log('error updating technologies', err);
    }
  }

  async finishOnboarding() {
    const {setHideWelcomeMessage} = this.props;
    try {
      await setHideWelcomeMessage();
      window.location.replace('/?first_time=true');
    } catch(err) {
      console.log('error setting welcome flag', err);
    }
  }

  goToNextStep = () => {
    if (!this.hasNextStep()) {
      return this.finishOnboarding();
    }

    this.setState({
      stepIndex: this.state.stepIndex + 1,
    });
  }

  goToPrevStep = () => {
    if (this.hasPrevStep()) {
      this.setState({
        stepIndex: this.state.stepIndex -1,
      });
    }
  }

  hasPrevStep = () => {
    return (this.state.stepIndex !== 0);
  }

  hasNextStep = () => {
    return (this.state.stepIndex < this.steps.length -1);
  }

  getCurrentStep = () => {
    return this.steps[this.state.stepIndex];
  }

  renderSteps = () => {
    const step = this.getCurrentStep();
    return this[step.renderFn]();
  }

  renderProfile() {
    const { firstName, lastName, phoneNumber, jobTitle, errors } = this.state;
    const {currentMember, i18n} = this.props;
    return (
      <div className={styles.profile}>
        <Title text={i18n.t('auth.onboarding.welcome')} />
        <ProfileImage
          wrapperStyle={{marginTop: 56}}
          updatable
          src={currentMember.medium_avatar_url}
          onImageChange={this.onImageChange}
        />
        <Input
          value={firstName}
          type="text"
          name="firstName"
          placeholder={i18n.t('auth.fields.first_name')}
          onChange={this.onInputChange}
          onFocus={this.clearError}
        />
        <Input
          value={lastName}
          type="text"
          name="lastName"
          placeholder={i18n.t('auth.fields.last_name')}
          onChange={this.onInputChange}
          onFocus={this.clearError}
        />
        <Input
          value={phoneNumber}
          type="text"
          name="phoneNumber"
          placeholder={i18n.t('auth.fields.mobile_number')}
          onChange={this.onInputChange}
          onFocus={this.clearError}
        />
        <Input
          value={jobTitle}
          type="text"
          name="jobTitle"
          placeholder={i18n.t('auth.fields.job_title')}
          onChange={this.onInputChange}
          onFocus={this.clearError}
        />
      </div>
    );
  }

  onSkillClicked = (skill) => {
    this.addOrRemoveItemToState('skills', skill);
  }

  onTechnologyClicked = (tech) => {
    this.addOrRemoveItemToState('preferredTechnologies', tech);
  }

  addOrRemoveItemToState = (name, item) => {
    const array = this.state[name];
    const itemSelected = array.findIndex(i => i.id === item.id) !== -1;
    if (itemSelected) {
      this.setState({
        [name]: array.filter(i => i.id !== item.id),
      });
    } else {
      this.setState({
        [name]: [...array, item],
      });
    }
  }

  renderSkills() {
    const {skills} = this.state;
    const {onboardingSkills, i18n} = this.props;
    return (
      <div className={styles.skills}>
        <div className={styles.header}>
          <Title text={i18n.t('auth.onboarding.add_skills')} />
          <Text className={styles.text}>{i18n.t('auth.onboarding.add_skills_subtitle')}</Text>
        </div>
        <div className={styles.skillsList}>
          {onboardingSkills.map(skill => (
            <ActionButton
              key={skill.id}
              body={skill.name}
              type={'primary'}
              active={skills.filter(s => s.id === skill.id).length > 0}
              onClick={() => this.onSkillClicked(skill)}
            />
          ))}
        </div>
      </div>
    );
  }

  renderTechnologies() {
    const {technologies, i18n} = this.props;
    const {preferredTechnologies} = this.state;
    return (
      <div className={styles.technologies}>
        <div className={styles.header}>
          <Title text={i18n.t('auth.onboarding.preferred_technologies')} />
          <Text className={styles.text}>{i18n.t('auth.onboarding.communication_preference')}</Text>
        </div>
        <div className={styles.technologiesList}>
          {technologies.map((technology, index) => (
            <Card
              style={{
                marginTop: 18,
                marginLeft: index === 0 ? 0 : 16,
                marginRight: index === technologies.length - 1 ? 0 : 16
              }}
              selectable
              key={technology.id}
              onClick={() => {
                this.onTechnologyClicked(technology);
              }}
              content={<img src={`/images/frontend/${technology.thumbnail_file_name}`} style={{objectFit: 'contain'}} />}
              title={technology.name}
              selected={preferredTechnologies.filter(t => t.id === technology.id).length > 0}
            />
          ))}
        </div>
      </div>
    );
  }

  isProfileReady() {
    const {firstName, lastName, phoneNumber, jobTitle} = this.state;
    return firstName !== '' && lastName !== '' && jobTitle !== '';
  }

  isSkillsReady() {
    const {skills} = this.state;
    return skills.length > 0;
  }

  isTechnologiesReady() {
    const {preferredTechnologies} = this.state;
    return preferredTechnologies.length > 0;
  }

  renderNav() {
    const { stepIndex } = this.state;
    if (stepIndex === 0) {
      return null;
    }
    return (
      <div className={styles.nav}>
        <img className={styles.back} src="/images/frontend/Icons_Left.svg" onClick={this.goToPrevStep} />
        <div onClick={this.goToNextStep} className={styles.skip}>
          <Text type="smallBodyBold" color="#333333" >{this.props.i18n.t('system_buttons.skip')}</Text>
        </div>
      </div>
    )
  }

  renderButton() {
    const step = this.getCurrentStep();
    const onClick = this[step.buttonCb];
    const isReady = this[step.formReady]();
    return (
      <div className={styles[`${step.id}Button`]}>
        <ActionButton
          type="primary"
          body={this.props.i18n.t('system_buttons.next')}
          active
          disabled={!isReady}
          disableSpinner
          onClick={onClick}
        />
      </div>
    )
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {this.renderNav()}
          <div className={styles.content}>
            {this.renderSteps()}
            {this.renderButton()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentMember: getCurrentMember(state),
  onboardingSkills: getOnboardingSkills(state),
  technologies: getTechnologies(state),
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    openPopup,
    setHideWelcomeMessage,
    fetchOnboardingSkills,
    fetchTechnologies,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withTranslator(Onboarding));
