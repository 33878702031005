import chroma from 'chroma-js';

const colors = {
  braidioBlue: '#0088FD',
  braidioGreen: '#2BC256',

  /* secondary */
  black: '#000',
  darkBlue: '#002E56',
  deepDarkBlue: '#000E1A',
  lightBlue: '#9DE3FF26',
  orange: '#FF8400',
  error: '#E93939',
  lightGrey: '#DDD',
  grey: '#333',
  altGrey: '#B3B3B3',
  backgroundGrey: '#F2F5F6',
  borderGrey: '#E6E6E6',
  textGray: '#808080',
  white: '#FFF',
};

colors.success = colors.braidioGreen;
colors.lighterGrey = chroma(colors.grey).alpha(0.5).hex('rgb');
colors.iconGrey = chroma(colors.grey).alpha(0.3).hex();
colors.disabledGrey = chroma(colors.grey).alpha(0.1).hex();
colors.lightWhite = chroma(colors.white).alpha(0.5).hex();
export default colors;
