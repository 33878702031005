import React from 'react';
import {useSelector} from 'react-redux';
import {getRegistrationSourceTheme} from '@shared/session/selectors';
import RouteWrapper from './RouteWrapper';

import styles from './PageWrapper.module.sass';

const PageWrapper = ({routes}) => {
  const registrationSourceTheme = useSelector(getRegistrationSourceTheme);

  return (
    <div className={styles.container} data-theme={registrationSourceTheme}>
      <div className={styles.routeContainer}>
        <RouteWrapper routes={routes} />
      </div>
    </div>
  );
};

export default PageWrapper;
