import urls from './urls';

import Login from '../pages/Login';
import Register from '../pages/Register';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import ResendInstructions from '../pages/ResendInstructions';
import Onboarding from "../pages/Onboarding";
import LoginMoreOptions from "../components/LoginMoreOptions";

export default [
  {
    path: urls.auth.login(),
    component: Login,
    props: {
      onboardingComponent: Onboarding,
      moreOptionsComponent: LoginMoreOptions,
    },
  },
  {
    path: urls.auth.register(),
    component: Register,
  },
  {
    path: urls.auth.forgotPassword(),
    component: ForgotPassword,
  },
  {
    path: urls.auth.resetPassword(),
    component: ResetPassword,
  },
  {
    path: urls.auth.sendConfirmationInstructions(),
    component: ResendInstructions,
  },
  {
    path: urls.auth.sso_signup(),
    component: Register,
  },
];
