/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable camelcase */

import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import validate from 'validate.js';
import {Formik, Form, Field} from 'formik';
import {isMobile} from 'react-device-detect';

import {withTranslator} from '@shared/components/wrappers';
import {openPopup} from '@shared/popups/actions';
import {getCurrentFacet} from '@shared/session/selectors';
import {login} from '@shared/session/actions';

import toast from '@shared/helpers/toast';
import TwoFactorVerification from '@auth/popups/TwoFactorVerification';
import PasswordExpiredPopup from '@auth/popups/PasswordExpired';
import Errors from '@shared/components/Errors';
import ActionButton from '@shared/components/ActionButton';
import EmailField from '@shared/components/formik/EmailField';
import PasswordField from '@shared/components/formik/PasswordField';
import Logo from '@shared/components/Logo';
import ClassNames from 'classnames';

import Title from '../components/Title';
import {enableConfirmationTokens} from '../app/selectors';
import {withCta} from './CtaWrapper';

import urls from '../app/urls';
import styles from './Login.module.sass';

validate.options ||= {};
validate.options.fullMessages = false;

const Login = ({
  history,
  i18n,
  onboardingComponent: OnboardingComponent,
  moreOptionsComponent: MoreOptionsComponent,
}) => {
  const dispatch = useDispatch();
  const searchParams = useLocation().search;
  const [emailParam, setEmailParam] = useState(null);
  const confirmationTokensEnabled = useSelector(enableConfirmationTokens);
  const currentFacet = useSelector(getCurrentFacet);

  const [serverErrors, setServerErrors] = useState([]);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  if (OnboardingComponent && showOnboarding) {
    return <OnboardingComponent />;
  }

  useEffect(() => {
    if (searchParams) {
      const params = new URLSearchParams(searchParams);
      setEmailParam(params.get('email'));
    }
  }, [searchParams]);

  const constraints = {
    email: {
      presence: {
        allowEmpty: false,
        message: () =>
          `${i18n.t('activerecord.attributes.user.email')} ${i18n.t(
            'errors.messages.blank',
          )}`,
      },
      email: {
        message: () =>
          `${i18n.t('activerecord.attributes.user.email')} ${i18n.t(
            'errors.messages.email',
          )}`,
      },
    },
    password: {
      presence: {
        allowEmpty: false,
        message: () =>
          `${i18n.t('activerecord.attributes.user.password')} ${i18n.t(
            'errors.messages.blank',
          )}`,
      },
    },
  };

  const initialValues = {email: emailParam, password: ''};

  const getVisibleErrors = (errors, touched) => {
    const visibleErrors = {};
    const keys = Object.keys(errors);
    keys.forEach((key) => {
      if (touched[key] === true) {
        visibleErrors[key] = errors[key];
      }
    });
    return visibleErrors;
  };

  const clearServerErrors = () => {
    setServerErrors([]);
  };

  const validateForm = ({email, password}) =>
    validate({email, password}, constraints);

  const onInputChange = () => {
    clearServerErrors();
  };

  const onForgotPassword = () => {
    history.push(urls.auth.forgotPassword());
  };

  const onResetPassword = () => {
    history.push(urls.auth.forgotPassword(true));
  };

  const onNeedInstructions = () => {
    history.push(urls.auth.sendConfirmationInstructions());
  };

  const onNoAccount = () => {
    const isWhatsappLogin = currentFacet === 'whatsapp';
    history.push(isWhatsappLogin ? urls.auth.onboarding() : urls.auth.signup());
  };

  const onMoreOptions = () =>
    setShowMoreOptions((currentShowMoreOptions) => !currentShowMoreOptions);
  const moreOptionsTitle = `${
    showMoreOptions ? i18n.t('main_nav.less') : i18n.t('main_nav.more')
  } ${i18n.t('auth.sign_in.options')}`;

  const openPasswordExpiredPopup = () =>
    dispatch(openPopup(<PasswordExpiredPopup onReset={onResetPassword} />));

  const onLoginSuccess = ({first_time, after_sign_in_path}) => {
    toast.hideAll();

    if (OnboardingComponent && first_time) {
      setShowOnboarding(true);
    } else {
      window.location.assign(after_sign_in_path || '/');
    }
  };

  const startTwoFactorVerification = (otpData, phoneVerified) => {
    toast.hideAll();

    return dispatch(
      openPopup(
        <TwoFactorVerification
          data={otpData}
          onAuthSuccess={onLoginSuccess}
          phoneVerified={phoneVerified}
          history={history}
        />,
      ),
    );
  };

  const authErrorMessage = (key = 'invalid') =>
    i18n.t(`devise.failure.${key}`, {
      _: i18n.t('devise.failure.invalid'),
    });

  const handleOtpError = (error) => {
    const {otp_state} = error;
    let errorMessage;
    switch (otp_state) {
      case 'too_many_attempts':
        errorMessage = `${i18n.t(
          'devise.two_factor_authentication.max_login_attempts_reached',
        )}. ${i18n.t(
          'devise.two_factor_authentication.contact_administrator',
        )}`;
        break;
      default:
        errorMessage = authErrorMessage(otp_state);
        break;
    }
    setServerErrors([errorMessage]);
  };

  const handleAuthError = (error) => {
    let errorMessage = null;

    switch (error.error) {
      case 'password_expired':
        openPasswordExpiredPopup();
        break;
      default:
        errorMessage = authErrorMessage(error.error);
        setServerErrors([errorMessage]);
    }
  };

  const handleError = (parsedError) => {
    const {code, otp_state} = parsedError;
    if (!code || code !== 401) {
      const errorMessage = authErrorMessage();
      setServerErrors([errorMessage]);
      return;
    }

    if (otp_state) handleOtpError(parsedError);
    else handleAuthError(parsedError);
  };

  const handleServerError = async (error) => {
    const parsedError = error.error;
    if (!parsedError) {
      setServerErrors([error]);
      return;
    }

    if (parsedError.otp_state) {
      switch (parsedError.otp_state) {
        case 'missing':
          startTwoFactorVerification(parsedError, false);
          break;
        case 'required':
          startTwoFactorVerification(parsedError, true);
          break;
        case 'too_many_attempts':
          handleError(parsedError);
          break;
        default:
          handleError(parsedError);
          // eslint-disable-next-line no-console
          console.log('unhandled otp state');
      }
    } else {
      handleError(parsedError);
    }
  };

  const onSignIn = async (values) => {
    const {email, password} = values;

    try {
      const session = await dispatch(login(email, password));
      onLoginSuccess(session);
    } catch (err) {
      handleServerError(err.message);
    }
  };

  return (
    <div className={ClassNames(styles.container, isMobile && styles.mobile)}>
      <Logo className={styles.logo} />
      <Title text={i18n.t('auth.sign_in.title')} />
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={onSignIn}>
        {({errors, isValid, touched, dirty, isSubmitting}) => (
          <Form className={styles.form} id="new_user">
            <Field
              name="email"
              placeholder={i18n.t('auth.fields.email')}
              autoFocus
              component={EmailField}
              customOnChange={onInputChange}
            />
            <Field
              name="password"
              placeholder={i18n.t('auth.fields.password')}
              component={PasswordField}
              customOnChange={onInputChange}
            />
            {dirty && <Errors errors={getVisibleErrors(errors, touched)} />}
            {serverErrors.length > 0 && <Errors errors={serverErrors} />}

            <div className={styles.actions}>
              <ActionButton
                type="text"
                align="right"
                body={i18n.t('auth.reset_password.forgot_your_password')}
                onClick={onForgotPassword}
              />
              <ActionButton
                justified
                active
                submit
                type="primary"
                body={i18n.t('auth.sign_in.button')}
                disabled={!isValid}
                loading={isSubmitting}
              />
              {confirmationTokensEnabled && (
                <ActionButton
                  type="text"
                  body={i18n.t(
                    'auth.reset_password.need_confirmation_instructions',
                  )}
                  onClick={onNeedInstructions}
                />
              )}
              {MoreOptionsComponent && (
                <ActionButton
                  type="text"
                  body={moreOptionsTitle}
                  onClick={onMoreOptions}
                />
              )}
              <ActionButton
                type="text"
                body={i18n.t('auth.sign_in.no_account')}
                onClick={onNoAccount}
              />
            </div>

            {MoreOptionsComponent && showMoreOptions && (
              <MoreOptionsComponent />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withCta(withTranslator(Login));
export const MobileLogin = withTranslator(Login);
