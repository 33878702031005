import React from 'react';
import Modal from '@shared/app/Modal';

const withModal = (WrappedComponent) => (props) => (
  <Modal>
    <WrappedComponent modal {...props} />
  </Modal>
);

export default withModal;
