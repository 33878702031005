import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';

function ErrorMessage({text, style}) {
  return (
    <div>
      <Text tag="span" type="caption" color="#E93939" customStyle={style}>{text}</Text>
    </div>
  );
};

ErrorMessage.defaultProps = {
  style: {},
}

ErrorMessage.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default ErrorMessage;
