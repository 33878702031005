import * as actions from './actions';

const initialState = {
  technologies: {},

};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case actions.TECHNOLOGIES_FETCHED:
      return {
        ...state,
        technologies: action.technologies,
      }
    default:
      return state;
  }
};

export default reducers;
