import React from "react";
import {createPortal} from "react-dom";
import styles from './Modal.module.sass';

const Modal = ({
  children,
}) => {

  return createPortal(
    <div className={styles.container}>
      {children}
    </div>,
    // modal_root DOM element defined in `application.html.haml`
    document.getElementById("modal_root"),
  );
}

export default Modal;
