import {concatUrlPaths} from '@shared/app/urls';

const fullUrl = (path) =>
  `https://${window.app_options.subdomain}.${window.app_options.domain_suffix}${path}`;

export default {
  meeting: {
    meeting: (token) => fullUrl(`/meetings/${token}`),
  },
  embed: {
    form: (formIdOrSlug, stepIndex) =>
      concatUrlPaths('/forms', formIdOrSlug, stepIndex),
    formList: (formListIdOrSlug, formIndex, stepIndex) =>
      concatUrlPaths('/form_lists', formListIdOrSlug, formIndex, stepIndex),
    meetings: (formSlug) => `/meetings/${formSlug}`,
    pets: (formSlug) => `/pets/${formSlug}`,
  },
  api: {
    inquiries: () => fullUrl(`/api/expert_chats`),
    inquiry: (token) => fullUrl(`/api/expert_chats/${token}`),
    experts: () => fullUrl(`/api/experts`),
    meetingRoom: (token) => fullUrl(`/api/meeting_rooms/${token}`),
    meetings: () => fullUrl(`/api/meetings`),
    forms: () => fullUrl(`/api/forms`),
    form: (id) => fullUrl(`/api/forms/${id}`),
    formLists: () => fullUrl(`/api/form_lists`),
    formList: (id) => fullUrl(`/api/form_lists/${id}`),
    paymentSetup: () => fullUrl(`/api/account/payments`),
  },
};
