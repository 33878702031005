import React from 'react';
import PropTypes from 'prop-types';
import ReactIs from 'react-is';
import classNames from 'classnames';

import styles from './Icon.module.sass';

const Icon = ({src, wrapperStyle, iconStyle}) => {
  const classes = classNames(styles.wrapper, wrapperStyle);

  return (
    <div className={classes}>
      <img className={iconStyle} src={src} />
    </div>
  );
};

export const iconSrcPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
  PropTypes.elementType,
]);

export const makeIcon = (src, iconStyle, wrapperStyle) => {
  if (!src) return undefined;

  if (typeof src === 'string')
    return <Icon src={src} iconStyle={iconStyle} wrapperStyle={wrapperStyle} />;

  if (ReactIs.isValidElementType(src))
    return React.createElement(src, {className: iconStyle || wrapperStyle});

  return src;
};

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  iconStyle: PropTypes.string,
  wrapperStyle: PropTypes.string,
};

export default Icon;
