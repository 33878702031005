import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import {COUNTRIES} from '@whatsapp/shared/helpers/constants';

export default function FlagSelect({
  label,
  onChange,
  className,
  onBlur,
  name,
  open,
  ...props
}) {
  const filterOptions = createFilterOptions({
    stringify: (option) => [option.label, option.code, option.phone].join(' ')
  });

  return (
    <Autocomplete
      className={className}
      sx={{width: 300}}
      options={COUNTRIES}
      filterOptions={filterOptions}
      disableClearable
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={onChange}
      renderOption={(optionProps, option) => (
        <Box
          component="li"
          sx={{'& > img': {mr: 2, flexShrink: 0}}}
          {...optionProps}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.phone})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
          variant="standard"
          fullWidth
          autoFocus={true}
        />
      )}
      onOpen={onBlur}
      disablePortal={true}
      open={open}
      fullWidth
      {...props}
    />
  );
}
