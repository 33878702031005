import React from 'react';
import PropTypes from 'prop-types';
import {Transition} from 'react-transition-group';
import {passwordValidators} from '@shared/helpers/validators';
import {withTranslator} from '@shared/components/wrappers';
import Text from '@shared/components/Text';
import styles from './PasswordValidator.module.sass';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0, zIndex: 1000 },
  entered:  { opacity: 1, zIndex: 1000 },
  exiting:  { opacity: 1 },
  exited:  { opacity: 0, zIndex: -1 },
};

const PasswordValidator = ({password, validators, i18n}) => {
  const validations = validators.map(validator => validator.method(password));

  const isPasswordValid = validations.every(v => v);
  return (
    <Transition in={!isPasswordValid} timeout={300} appear>
    {state => (
      <div className={styles.wrapper} style={{
        ...defaultStyle,
        ...transitionStyles[state]
      }}>
        <Text type="smallBody" customStyle={{marginBottom: 8, marginTop: 0}}>Password Requirements</Text>
        {validators.map((validator, index) => (
          <Text
            key={index}
            color={validations[index] ? '#2BC256' : '#333333'}
            customStyle={{margin: 0}}
          >
            {i18n.t(validator.text, {count: validator.count})}
          </Text>
        ))}
      </div>
    )}

    </Transition>
  );
}

PasswordValidator.propTypes = {
  password: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    // method should take string as argument and return true if it passes validation
    method: PropTypes.func,
  })).isRequired,
};

PasswordValidator.defaultProps = {
  validators: passwordValidators,
};

export default withTranslator(PasswordValidator);
