import React from 'react';
import {useSelector} from 'react-redux';

import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'

class TimeFormatter extends Intl.RelativeTimeFormat {
  DIVISIONS = [
    { divider: 60, unit: 'seconds' },
    { divider: 60, unit: 'minutes' },
    { divider: 24, unit: 'hours' },
    { divider: 7, unit: 'days' },
    { divider: 4.34524, unit: 'weeks' },
    { divider: 12, unit: 'months' },
    { divider: Number.POSITIVE_INFINITY, unit: 'years' },
  ];

  getDuration(date) {
    return (date - new Date()) / 1000;
  }

  getParts(duration) {
    for (let i = 0; i <= this.DIVISIONS.length; i++) {
      const {divider, unit} = this.DIVISIONS[i];
      if (Math.abs(duration) < divider) {
        return ({value: Math.round(duration), unit});
      }
      duration /= divider;
    }
  };

  getTimeAgo(date, unit) {
    const duration = this.getDuration(new Date(date));
    const parts = this.getParts(duration);

    return this.format(parts.value, unit || parts.unit);
  };
}

const withTimeFormatter = (WrappedComponent) => (props) => {
  const locale = useSelector(state => state.railsContext.i18nLocale)
  const timeFormatter = new TimeFormatter(locale, {
    numeric: 'auto',
    style: 'short',
  });

  return <WrappedComponent {...props} timeFormatter={timeFormatter}/>;
};

export default withTimeFormatter;
