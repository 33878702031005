
let siteName;

export const initTitleServiceWithStore = (store) => {
  siteName = store.getState().session.site_name;
};

export const setTitle = (title) => {
  if (title)
    document.title = `${siteName} - ${title}`;
  else
    document.title = siteName;
};