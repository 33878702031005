import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {getRegistrationSourceTheme} from '@shared/session/selectors';
import {getPopupComponent, getPopupOptions} from './selectors';
import {closePopup} from './actions';

import styles from './Popup.module.sass';

const POPUP_ID = 'popupContainer';
const CANVAS_ID = 'canvasContainer';
const ESC_KEY = 27;

const Popup = () => {
  const dispatch = useDispatch();
  const registrationSourceTheme = useSelector(getRegistrationSourceTheme);
  const component = useSelector(getPopupComponent);
  const options = useSelector(getPopupOptions);
  const [closeOnOutsideClick, setCloseOnOutsideClick] = useState(
    options.cancelable && options.closeOnOutsideClick,
  );
  const [closeOnEscape, setCloseOnEscape] = useState(
    options.cancelable && options.closeOnEscape,
  );
  const isVisible = () => !!component;

  const close = () => dispatch(closePopup());

  const onKeyUp = (e) => {
    if (closeOnEscape && e.keyCode === ESC_KEY) {
      close();
    }
  };

  useEffect(() => {
    setCloseOnOutsideClick(options.closeOnOutsideClick && options.cancelable);
    setCloseOnEscape(options.closeOnEscape && options.cancelable);
  }, [options.closeOnEscape, options.closeOnOutsideClick, options.cancelable]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isVisible() && closeOnEscape) {
      document.addEventListener('keyup', onKeyUp, false);

      return () => {
        document.removeEventListener('keyup', onKeyUp, false);
      };
    }
  }, [component, closeOnEscape]);

  const onClick = (e) => {
    if (closeOnOutsideClick && e.target?.id === POPUP_ID) {
      close();
      if (options.handleClose) options.handleClose();
    }
  };

  if (!isVisible()) {
    return null;
  }

  if (options.canvas) {
    return (
      <canvas id={CANVAS_ID} data-theme={registrationSourceTheme}>
        {component}
      </canvas>
    );
  }
  return (
    <div
      className={styles.container}
      onClick={onClick}
      id={POPUP_ID}
      data-theme={registrationSourceTheme}>
      {component}
    </div>
  );
};

export default Popup;
