import * as settingsActions from '@frontend/settings/actions';

const initialState = {
  automatic_messages: {
    end_chat: {
      isLoading: false,
    },
    reminder: {
      isLoading: false,
    },
    welcome: {
      isLoading: false,
    },
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case settingsActions.PATCH_AUTOMATIC_MESSAGES:
      return {
        ...state,
        automatic_messages: {
          [action.optimistic.key]: {
            enabled: action.optimistic.enabled,
          },
          isLoading: true,
        },
      };

    case settingsActions.PATCH_AUTOMATIC_MESSAGES_SUCCEEDED:
      return {
        ...state,
        automatic_messages: {
          ...state.automaticMessages,
          ...action.automaticMessages,
          isLoading: false,
        },
      };

    case settingsActions.PATCH_AUTOMATIC_MESSAGES_FAILED:
      return {
        ...state,
        automatic_messages: {
          ...(state.automatic_messages ?? {}),
          [action.key]: {
            ...state.automatic_messages[action.key],
            enabled: action.backup,
          },
          isLoading: false,
        },
      };

    default:
      return state;
  }
};

export default reducers;
