import {configureStore} from '@reduxjs/toolkit';
import optimisticMiddleware from '@app/middlewares/optimistic';

export default (reducers, preloadedState) => {
  return configureStore({
    reducer: reducers,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(optimisticMiddleware),
  });
};
