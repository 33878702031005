/* eslint-disable camelcase */
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import validate from 'validate.js';
import {Formik, Form, Field} from 'formik';
import {resetPassword} from '@shared/session/actions';
import {passwordValidators} from '@shared/helpers/validators';
import urls from '../app/urls';

import {withTranslator} from '@shared/components/wrappers';
import PasswordField from '@shared/components/formik/PasswordField';
import ActionButton from '@shared/components/ActionButton';
import Text from '@shared/components/Text';
import Errors from '@shared/components/Errors';
import Logo from '@shared/components/Logo';

import Title from '../components/Title';
import PasswordValidator from '../components/PasswordValidator';
import {withCta} from './CtaWrapper';

import styles from './Login.module.sass';

const ResetPassword = ({history, i18n}) => {
  const [emailSent, setEmailSent] = useState(false);
  const [showPasswordValidator, setShowPasswordValidator] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const dispatch = useDispatch();

  const constraints = {
    confirmPassword: {
      equality: {
        attribute: 'password',
        message: () => `${i18n.t('member_signup_page.password_confirm_error')}`,
      },
    },
  };

  const handleServerError = async (error, setSubmitting) => {
    const parsedError = error.error;
    const errors = parsedError?.message;
    setServerErrors(errors || [this.props.i18n.t('errors.messages.generic')]);
    setSubmitting(false);
  };

  const onReset = (values, {setSubmitting}) => {
    const urlParams = new URLSearchParams(window.location.search);
    const reset_password_token = urlParams.get('reset_password_token');

    dispatch(resetPassword(values.password, reset_password_token))
      .then(() => {
        setEmailSent(true);
      })
      .catch((err) => {
        handleServerError(err.message, setSubmitting);
      });
  };

  const onPasswordFocus = (e) => {
    setShowPasswordValidator(true);
  };

  const clearErrors = () => {
    setServerErrors([]);
  };

  const onPasswordChange = (e) => {
    clearErrors();
  };

  const onPasswordBlur = (e) => {
    setShowPasswordValidator(false);
  };

  const validateForm = ({password, confirmPassword}) => {
    const errors = {};
    const passwordErrors = passwordValidators
      .filter((validator) => !validator.method(password))
      .map((v) => i18n.t(v.text));
    const confirmPasswordErrors = validate(
      {password, confirmPassword},
      constraints,
    );
    if (passwordErrors.length > 0) {
      errors.password = passwordErrors;
    }
    if (confirmPasswordErrors) {
      errors.confirmPassword = confirmPasswordErrors.confirmPassword;
    }
    return errors;
  };

  const goBack = () => {
    history.push(urls.auth.login());
  }

  const renderForm = () => (
    <Formik
      initialValues={{password: '', confirmPassword: ''}}
      validate={validateForm}
      validateOnMount
      onSubmit={onReset}>
      {({errors, isValid, values, dirty, isSubmitting, handleBlur}) => (
        <Form className={styles.form}>
          <Title text={i18n.t('auth.reset_password.reset_password')} />
          <Field
            component={PasswordField}
            name="password"
            autoComplete="new-password"
            placeholder={i18n.t('auth.fields.new_password')}
            customOnChange={onPasswordChange}
            onFocus={onPasswordFocus}
            onBlur={(e) => {
              handleBlur(e);
              onPasswordBlur(e);
            }}
          />
          <Field
            component={PasswordField}
            name="confirmPassword"
            autoComplete="new-password"
            placeholder={i18n.t('auth.fields.confirm_password')}
            customOnChange={onPasswordChange}
          />
          {dirty && values["confirmPassword"] !== '' && (
            <Errors errors={errors.confirmPassword} />
          )}
          {serverErrors.length > 0 && <Errors errors={serverErrors} />}
          {showPasswordValidator && (
            <PasswordValidator password={values["password"]} />
          )}
          <ActionButton
            active
            justified
            type="primary"
            body={i18n.t('system_buttons.reset')}
            style={{marginTop: 24}}
            submit
            loading={isSubmitting}
            disabled={!isValid}
          />
        </Form>
      )}
    </Formik>
  );

  const renderSuccess = () => (
    <div className={styles.response}>
      <Title text={i18n.t('main_nav.success')} />
      <Text color="#808080">
        {i18n.t('auth.reset_password.reset_confirmation')}
      </Text>
      <ActionButton
        justified
        active
        type="primary"
        body={i18n.t('auth.sign_in.back_to')}
        onClick={goBack}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      <Logo />
      {emailSent ? renderSuccess() : renderForm()}
    </div>
  );
};

export default withCta(withTranslator(ResetPassword));
