/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';

function Errors({errors, style, touched}) {
  const renderArray = (errorList) => {
    if (!errorList) return null;

    return errorList.map((message, index) => (
      <ErrorMessage key={index} text={message} style={style} />
    ));
  };

  const renderHash = (errorHash) =>
    Object.entries(errorHash).map(([fieldName, errors]) => {
      if (touched && !touched[fieldName]) return null;

      if (typeof errors === 'string') errors = [errors];

      return (
        <React.Fragment key={fieldName}>{renderArray(errors)}</React.Fragment>
      );
    });

  if (typeof errors === 'string') {
    return renderArray([errors]);
  }

  if (Array.isArray(errors)) {
    return renderArray(errors);
  }

  return renderHash(errors);
}

Errors.defaultProps = {
  errors: [],
  style: {
    marginTop: 8,
    display: 'inline-block',
  },
};

Errors.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.objectOf(PropTypes.array),
  ]).isRequired,
  touched: PropTypes.object,
};

export default Errors;
