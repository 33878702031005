const fontFamily = 'MontSerrat, Helvetica, sans-serif';

export default {
  textStyles: {
    h1: {
      tag: 'h1',
      fontSize: '80px',
      fontWeight: 300,
      lineHeight: '88px',
      fontFamily,
      letterSpacing: '-0.005em',
    },
    h2: {
      tag: 'h2',
      fontSize: '64px',
      lineHeight: '72px',
      fontWeight: 300,
      fontFamily,
      letterSpacing: '-0.005em',
    },
    h3: {
      tag: 'h3',
      fontSize: '36px',
      lineHeight: '40px',
      fontWeight: 300,
      fontFamily,
      letterSpacing: '-0.005em',
    },
    h3Bold: {
      tag: 'h3',
      fontSize: '36px',
      lineHeight: '40px',
      fontWeight: 700,
      fontFamily,
      letterSpacing: '-0.005em',
    },
    h4: {
      tag: 'h4',
      fontSize: '64px',
      lineHeight: '72px',
      fontWeight: 300,
      fontFamily,
      letterSpacing: '-0.005em',
    },
    h5: {
      tag: 'h5',
      fontSize: '26px',
      lineHeight: '32px',
      fontWeight: 300,
      fontFamily,
      letterSpacing: '-0.005em',
      color: '#333333',
    },
    h5Bold: {
      tag: 'h5',
      fontSize: '26px',
      lineHeight: '32px',
      fontWeight: 700,
      fontFamily,
      letterSpacing: '-0.005em',
      color: '#333333',
    },
    h6: {
      tag: 'h6',
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 300,
      fontFamily,
      letterSpacing: '-0.005em',
    },
    body: {
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily,
      fontWeight: 300,
      color: '#808080',
    },
    bodyBold: {
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily,
      fontWeight: 500,
      margin: 0,
    },
    bodyExtraBold: {
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily,
      fontWeight: 700,
      margin: 0,
    },
    smallBody: {
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily,
      fontWeight: 300,
      color: '#808080',
      margin: 0,
    },
    smallBodyBold: {
      fontSize: '14px',
      fontFamily,
      fontWeight: 500,
      margin: 0,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '16px',
      fontFamily,
      fontWeight: 300,
      margin: 0,
    },
    captionBold: {
      fontSize: '12px',
      lineHeight: '16px',
      fontFamily,
      fontWeight: 500,
    },
    button: {
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily,
      fontWeight: 'bold',
    },
    smallButton: {
      fontSize: '14px',
      lineHeight: '16px',
      fontFamily,
      fontWeight: 'bold',
    },
  },
};
