import React, {Component} from 'react';
import validate from 'validate.js';
import Polyglot from 'node-polyglot';

import {createReduxStore} from '@shared/app/ReactHelpers';
import {initLoggingWithStore} from '@shared/utilities/logging';
import {initTitleServiceWithStore} from '@shared/utilities/title';
import {displayFlashMessages} from '@shared/utilities/flash_messages';

import 'react-toastify/dist/ReactToastify.css';
import '@shared/styles/toast.sass';

class BaseApp extends Component {
  constructor(props, reducers) {
    super(props);
    this.store = createReduxStore(reducers);

    this.i18n = new Polyglot({phrases: props.i18n_t});

    // #todo - Move this out of the subclasses into the base class see card BP-533
    // initUnauthorizedRequestHandler(handleUnauthorizedRequest, this.store);
    validate.options ||= {};
    validate.options.i18n = this.i18n;

    initLoggingWithStore(this.store);
    initTitleServiceWithStore(this.store);
    displayFlashMessages(this.store);
  }
}

export default BaseApp;
