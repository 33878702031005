import React, {useContext} from 'react';
import I18nContext from '@shared/i18n/context';

const withTranslator = (WrappedComponent) => (props) => {
  const i18n = useContext(I18nContext);

  return <WrappedComponent {...props} i18n={i18n} />;
};

export default withTranslator;
