import {useEffect} from 'react';

const useClickOutsideDetector = (ref, onClickOutsideDetected) => useEffect(() => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutsideDetected();
    }
  }

  // Bind the event listener
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    // Unbind the event listener on clean up
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [ref]);

export default useClickOutsideDetector;
