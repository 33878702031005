// These are in shared since somce code is used outside of the Leia bundle

import {createSelectorFromKey} from '@shared/utilities/selectors';
// eslint-disable-next-line import/no-cycle
import {hasFeature} from '@shared/registration/selectors';

const getRoot = (state) => state.session.leia;
const createRootSelector = (key) => createSelectorFromKey(getRoot, key);

export const isLeia = (state) => !!getRoot(state) || hasFeature('leia')(state);

export const getPartner = createRootSelector('partner');
