/* eslint-disable no-param-reassign */
import {createSlice} from '@reduxjs/toolkit';

const formsSlice = createSlice({
  name: 'forms',
  initialState: {
    isLoaded: false,
    isLoading: false,
    isError: false,
    data: [],
  },
  reducers: {
    loading(state) {
      state.isLoaded = false;
      state.isLoading = true;
    },
    received(state, action) {
      state.isLoaded = true;
      state.isLoading = false;
      state.isError = false;
      state.data = action.payload;
    },
    receivedOne(state, action) {
      state.isLoaded = true;
      state.isLoading = false;
      state.isError = false;
      state.data.push(action.payload);
    },
    setFieldOptions(state, action) {
      const {formId, stepIndex, fieldIndex, options} = action.payload;
      const form = state.data.find((f) => f.id === formId);
      if (!form?.id) return;

      form.steps[stepIndex].fields[fieldIndex].options = options;
    },
    error(state) {
      state.isError = true;
      state.isLoading = false;
    },
  },
});

const {actions, reducer} = formsSlice;

export default reducer;

export const {loading, received, receivedOne, setFieldOptions, error} = actions;
