import React from 'react';
import { Switch, Route } from "react-router-dom";
import NotFound404Page from '@shared/components/NotFound404Page';

const renderComponent = (Component, props) =>
  (builtInProps) => <Component {...builtInProps} {...props} />;

const renderRoute = ({component, props, ...routeProps}, index) =>
  <Route key={index} render={renderComponent(component, props)} {...routeProps} />;

const RouteWrapper = ({routes}) => (
  <Switch>
    { routes.map(renderRoute) }

    <Route component={NotFound404Page} />
  </Switch>
);

export default RouteWrapper;
