import toast from '@shared/helpers/toast';

// Use an empty string to hide messages

const logLevels = {
    notice:  'info',
    warning: 'warn',
    error:   'error',
    success: 'info',
    alert:   'warn',
}

const toastLevels = {
    notice:  'info',
    warning: 'warning',
    error:   'error',
    success: 'info',
    alert:   'warning',
}

const displayFlashMessage = (level, message) => {
    if (!message)
        return;

    const logLevel = logLevels[level] ?? 'error';
    if (logLevel)
        console[logLevel](`${level.toUpperCase()}: ${message}`);

    const toastLevel = toastLevels[level] ?? 'error';
    if (toastLevel)
        toast[toastLevel](message);
}

export const displayFlashMessages = (store) => {
    const context = store.getState().railsContext;
    const flash   = context.flash;
    if (flash) {
        for (const level in flash)
            displayFlashMessage(level, flash[level])
    }
}
