export const passwordValidators = [
  {
    text:
      'activerecord.errors.models.user.attributes.password.password_complexity.alphas',
    count: '2',
    method: (password) => /[A-Za-z].*?[A-Za-z]/.test(password),
  },
  {
    text:
      'activerecord.errors.models.user.attributes.password.password_complexity.digits',
    count: '2',
    method: (password) => /\d.*?\d/.test(password),
  },
  {
    text:
      'activerecord.errors.models.user.attributes.password.password_complexity.symbols',
    count: '2',
    method: (password) =>
      /(?=(.*[:;<>~[\]"§`!@#$%\^&*\-_=\+'/\.,\\]){2})/.test(password),
  },
  {
    text:
      'activerecord.errors.models.user.attributes.password.password_complexity.length',
    count: '8',
    method: (password) => password.length >= 8,
  },
];

export const requiredValidator = (value) => !value && 'required';
