import React from 'react';
import ReactDOM from 'react-dom';
import storeBuilder from './storeBuilder';

let storeInstance, appName, appComponent;

const getReactJson = (type) => {
  const el = document.querySelector(`script.js-react-json-data[data-type="${type}"]`);
  if (el) {
    return JSON.parse(el.textContent);

  } else {
    console.warn(`React JSON script not found for type="${type}"`);
    return {};
  }
};

const renderApp = () => {
  const $container = document.getElementById('react-container');
  if (!$container) {
    return;
  }

  console.info('Rendering react app', appName);
  const props = getReactJson('props');
  const element = React.createElement(appComponent, props);
  ReactDOM.render(element, $container);

  return element;
};

export const initializeReactApp = (name, app) => {
  if (appName || appComponent)
    throw(`initializeReactApp(${name}): App component already set to ${appName}`);

  appName = name;
  appComponent = app;

  document.addEventListener('DOMContentLoaded', renderApp);
};

export const createReduxStore = (reducers) => {
  if (!reducers)
    throw('createReduxStore: No reducers set');

  if (!storeInstance) {
    const state = getReactJson('state');
    storeInstance = storeBuilder(reducers, state);
  }

  return storeInstance;
};

