import {createSelector} from 'reselect';

export const createSelectorFromKey = (selector, key) =>
  createSelector(selector, (attribute) => attribute && attribute[key]);

export const createSelectorFromKeys = (selector, ...args) =>
  createSelector(selector, (attribute) =>
    args.reduce((attribute, key) => attribute && attribute[key], attribute),
  );

export const removeDupesById = (ary) =>
  ary.reduce(
    (items, item) =>
      items.find((i) => i.id === item.id) ? items : [...items, item],
    [],
  );
