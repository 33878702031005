import {createSelector} from '@reduxjs/toolkit';
import {createSelectorFromKey} from '@shared/utilities/selectors';

const getRoot = (state) => state.formLists;
const createRootSelector = (key) => createSelectorFromKey(getRoot, key);

export const getIsLoaded = createRootSelector('isLoaded');
export const getIsLoading = createRootSelector('isLoading');
export const getIsError = createRootSelector('isError');
export const getFormLists = createRootSelector('data');

export const getIsOrShouldLoad = createSelector(
  getIsLoading,
  getIsLoaded,
  (isLoading, isLoaded) => isLoading && !isLoaded,
);

export const getFormListById = (formListId) =>
  createSelector(getFormLists, (formLists) =>
    formLists?.find((f) => f.id === formListId),
  );

export const getFormListBySlug = (formListSlug) =>
  createSelector(getFormLists, (formLists) =>
    formLists?.find((f) => f.slug === formListSlug),
  );

export const getFormList = ({slug, id}) => {
  if (slug) {
    return getFormListBySlug(slug);
  }
  if (id) {
    return getFormListById(id);
  }
  return null;
};
