import {useContext} from 'react';
import I18nContext from '@shared/i18n/context';

const useTranslator = (...contextKeys) => {
  const i18n = useContext(I18nContext);

  const context = contextKeys.join('.');
  if (!context) return i18n;

  return {
    ...i18n,
    has: (key) => i18n.has(`${context}.${key}`),
    t: (key, ...args) => i18n.t(`${context}.${key}`, ...args),
  };
};

export default useTranslator;
