import React from 'react';
import PropTypes from 'prop-types';

function HtmlContent({content, ...props}) {
  return <span {...props} dangerouslySetInnerHTML={{__html: content}} />;
}

HtmlContent.propTypes = {
  content:   PropTypes.string,
  className: PropTypes.string,
};

export default HtmlContent;
