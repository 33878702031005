import { setUnauthorizedRequestInterceptor } from '@shared/utilities/unauthorized_request_interceptor';
import { reasonFromResponse } from '@shared/services/api';

let store;
let handler;

// Flag to ensure that we don't trigger multiple errors
// it is cleared when an auth error occurs and needs to be reset
// by calling `resetUnauthorizedRequestHandler()`
let interceptionEnabled = true;

export const defaultUnauthorizedHandler = ({reason, options, responseJson}) => {
  console.warn('Unauthorized - reloading', reason);

  // Make sure the window reloads outside of any redux or ajax request loops
  setTimeout( () => window.location.reload(), 0 );
}

const unauthorizedRequestInterceptor = async ({response, request, options} ) => {
  response.handled = true;
  if (!interceptionEnabled)
    return;

  const [reason, responseJson] = await reasonFromResponse(response);

  const result = (handler || defaultUnauthorizedHandler)({reason, options, responseJson});

  interceptionEnabled = false;

  if (store)
    return store.dispatch(result)
  else
    return result;
}

const resetUnauthorizedRequestHandler = () => {
  interceptionEnabled = true;
}

const initUnauthorizedRequestHandler = (requestHandler, reduxStore) => {
  handler = requestHandler;
  store   = reduxStore;

  setUnauthorizedRequestInterceptor(unauthorizedRequestInterceptor);
  resetUnauthorizedRequestHandler();
}

export { initUnauthorizedRequestHandler, resetUnauthorizedRequestHandler };