import * as actions from './actions';

const initialState = {
  skills: [],
  onboardingSkills: [],
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case actions.SKILLS_FETCHED:
      return {
        ...state,
        skills: action.skills,
      }
    case actions.ONBOARDING_SKILLS_FETCHED:
      return {
        ...state,
        onboardingSkills: action.onboardingSkills,
      }
    default:
      return state;
  }
};

export default reducers;
