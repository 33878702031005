import React from 'react';
import {useSelector} from 'react-redux';

import {getLogoSrc} from '@shared/registration/selectors';

import styles from './Logo.module.sass';

const Logo = ({src, className}) => {
  const registrationLogoSrc = useSelector(getLogoSrc);
  const logoSrc = src || registrationLogoSrc;

  return <img src={logoSrc} className={className ?? styles.logo} />;
};

export default Logo;
