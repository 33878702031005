import React from 'react';
import {toast} from 'react-toastify';
import Toast from '@shared/toast/Toast';

const defaultOptions = {
  position:         toast.POSITION.BOTTOM_CENTER,
  closeButton:      false,
  pauseOnHover:     true,
  pauseOnFocusLoss: true,
  newestOnTop:      true,
};

const manualClose = {
  autoClose:       false,
  hideProgressBar: true,
}

const successOptions = {
  ...defaultOptions,
}

const infoOptions = {
  ...defaultOptions,
}

const staticOptions = {
  position:         toast.POSITION.TOP_CENTER,
  autoClose:        false,
  closeOnClick:     false,
  draggable:        true,
  hideProgressBar:  true,
}

const promptOptions = {
  ...infoOptions,
  ...manualClose,
  closeButton: true
}

const warningOptions = {
  ...defaultOptions,
  ...manualClose,
}

const errorOptions = {
  ...defaultOptions,
  ...manualClose,
}

const render = (message, props = {}) => <Toast message={message} {...props} />;

const success = (message, options) => {
  return toast.success(render(message), {...successOptions, ...options});
};

const info = (message, options) => {
  return toast.info(render(message), {...infoOptions, ...options});
};

const staticNotification = (message, options) => {
  return toast.info(render(message), {...infoOptions, ...staticOptions, ...options});
};

const prompt = (message, buttonText, onButtonClick) => {
  return toast.info(render(message, {buttonText, onButtonClick}), promptOptions);
}

const warning = (message, options) => {
  return toast.warn(render(message), {...warningOptions, ...options});
};

const error = (message, options) => {
  return toast.error(render(message), {...errorOptions, ...options});
};

const hide = toastId => {
  return toast.dismiss(toastId);
}

const hideAll = () => {
  toast.clearWaitingQueue();
  return toast.dismiss();
}

export default {
  success,
  info,
  staticNotification,
  prompt,
  warning,
  error,
  hide,
  hideAll,
}
