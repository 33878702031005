import React from 'react';
import {useSelector} from 'react-redux';

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

import {getPublishableKey, getOptions} from '@shared/selectors/stripe';

let stripePromise;

const withStripeElements = (WrappedComponent) => (props) => {
  const stripeKey = useSelector(getPublishableKey);
  const stripeOptions = useSelector(getOptions);

  if (!stripeKey) {
    return <div style={{color: 'red'}}>Stripe Key is not set</div>;
  }

  stripePromise ||= loadStripe(stripeKey);

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <WrappedComponent {...props} />
    </Elements>
  );
};

export default withStripeElements;
