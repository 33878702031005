import urls from '../app/urls';
import {get, post, put, del} from '../services/api';

export const actionTypes = {
  fetching: 'KNOWLEDGE_TAPS.FETCHING',
  received: 'KNOWLEDGE_TAPS.RECEIVED',
  oneReceived: 'KNOWLEDGE_TAPS.ONE_RECEIVED',
  created: 'KNOWLEDGE_TAPS.CREATED',
  updated: 'KNOWLEDGE_TAPS.UPDATED',
  deleted: 'KNOWLEDGE_TAPS.DELETED',
  updateSort: 'KNOWLEDGE_TAPS.UPDATE_SORT',
  addFilter: 'KNOWLEDGE_TAPS.ADD_FILTER',
  removeFilter: 'KNOWLEDGE_TAPS.REMOVE_FILTER',
  subscriptionsReceived: 'KNOWLEDGE_TAPS.SUBSCRIPTIONS_RECEIVED',
};

const buildPayload = (knowledgeTap) => {
  const formData = new FormData();

  formData.append('question[title]', knowledgeTap.title);
  formData.append('question[body]', knowledgeTap.body);
  knowledgeTap.skills.forEach((skill, i) => {
    if (skill.id && skill.destroy && skill.created_at) {
      formData.append(`question[skill_ids][]`, skill.id);
      formData.append(`question[skills_attributes][${i}][_destroy]`, '1');
      formData.append(`question[skills_attributes][${i}][id]`, skill.id);
    } else if (skill.id) {
      formData.append(`question[skill_ids][]`, skill.id);
    } else if (skill.name) {
      formData.append(`question[skills_attributes][${i}][name]`, skill.name);
    }
  });

  if (knowledgeTap.image?.id && knowledgeTap.image?.destroy) {
    formData.append('question[image_attributes][id]', knowledgeTap.image.id);
    formData.append('question[image_attributes][_destroy]', '1');
  } else if (knowledgeTap.image?.file) {
    formData.append('question[image]', knowledgeTap.image.file);
  }

  return formData;
};

const startFetching = () => ({
  type: actionTypes.fetching,
});

export const setSortBy = (sortBy) => ({
  type: actionTypes.updateSort,
  sortBy,
});

export const addFilter = (filter) => ({
  type: actionTypes.addFilter,
  filter,
});

export const removeFilter = (filter) => ({
  type: actionTypes.removeFilter,
  filter,
});

const knowledgeTapsReceived = (knowledgeTaps) => ({
  type: actionTypes.received,
  knowledgeTaps,
});

const knowledgeTapReceived = (knowledgeTap) => ({
  type: actionTypes.oneReceived,
  knowledgeTap,
});

const knowledgeTapCreated = (knowledgeTap) => ({
  type: actionTypes.created,
  knowledgeTap,
});

const knowledgeTapUpdated = (knowledgeTap) => ({
  type: actionTypes.updated,
  knowledgeTap,
});

const knowledgeTapsDeleted = (knowledgeTapIds) => ({
  type: actionTypes.deleted,
  knowledgeTapIds,
});

const memberSubscriptionsReceived = (subscriptions) => ({
  type: actionTypes.subscriptionsReceived,
  subscriptions,
});

export const fetchKnowledgeTaps = () => async (dispatch) => {
  dispatch(startFetching());
  const res = await get(urls.api.knowledgeTaps());
  return dispatch(knowledgeTapsReceived(res));
};

export const fetchKnowledgeTap = (id) => async (dispatch) => {
  dispatch(startFetching());
  const res = await get(urls.api.knowledgeTap(id));
  return dispatch(knowledgeTapReceived(res));
};

export const createKnowledgeTap = (knowledgeTap) => async (dispatch) => {
  const res = await post(urls.api.knowledgeTaps(), buildPayload(knowledgeTap));
  return dispatch(knowledgeTapCreated(res));
};

export const updateKnowledgeTap = (knowledgeTap) => async (dispatch) => {
  const res = await put(
    urls.api.knowledgeTap(knowledgeTap.id),
    buildPayload(knowledgeTap),
  );
  return dispatch(knowledgeTapUpdated(res));
};

export const deleteKnowledgeTap = (knowledgeTapId) => async (dispatch) => {
  await del(urls.api.knowledgeTap(knowledgeTapId));
  return dispatch(knowledgeTapsDeleted(knowledgeTapId));
};

export const deleteKnowledgeTaps = (questionIds) => async (dispatch) => {
  const questions = await del(
    Array.isArray(questionIds)
      ? urls.api.knowledgeTapsBulk(questionIds)
      : urls.api.knowledgeTap(questionIds),
  );
  return dispatch(knowledgeTapsDeleted(questions.map((q) => q.id)));
};

export const updateMemberSubscriptions = (subscriptions) => async (
  dispatch,
) => {
  const resp = await post(urls.api.updateMemberSubscriptions(), {
    member: {subs_attributes: subscriptions},
  });
  return dispatch(memberSubscriptionsReceived(resp));
};

export const testables = {
  buildPayload,
  knowledgeTapsReceived,
  knowledgeTapReceived,
  knowledgeTapsDeleted,
};
