import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import ReactIs from 'react-is';
import classNames from 'classnames';
import {useIsMounted} from '@shared/hooks';

import {makeIcon, iconSrcPropType} from '@shared/components/Icon';
import Loader from '@shared/components/Loader';
import Text from '@shared/components/Text';

import CloseIcon from '@shared/images/close-icon.svg';
import colors from '@shared/helpers/colors';
import styles from './ActionButton.module.sass';

const buttonBody = (body, type, iconSrc, iconStyle) => {
  if (typeof body === 'string') {
    return (
      <>
        {makeIcon(iconSrc, iconStyle)}
        <Text
          type={type === 'primary' ? 'button' : 'smallButton'}
          color="inherit"
          className={styles.buttonText}>
          {body}
        </Text>
      </>
    );
  } else if (!body) {
    return makeIcon(iconSrc, iconStyle);
  } else if (ReactIs.isValidElementType(body)) {
    return React.createElement(body);
  } else {
    return body;
  }
};

const removeIcon = ({onRemove}) => {
  return (
    <div className={styles.removeIconWrapper} onClick={onRemove}>
      <CloseIcon color={colors.iconGrey} />
    </div>
  );
};

const notificationOverlay = (text) => {
  if (text === undefined) return null;

  if (typeof text === 'number') {
    if (text === 0) return null;
    if (text > 9) text = '9+';
  }

  return <div className={styles.notificationText}>{text}</div>;
};

const ActionButton = ({
  active,
  align,
  body,
  className,
  disabled,
  disableSpinner,
  iconSrc,
  iconStyle,
  justified,
  loading,
  onClick,
  onRemove,
  removable,
  style,
  submit,
  type,
  notificationText,
  ...props
}) => {
  const buttonClassName = classNames(
    className,
    styles.wrapper,
    styles[type],
    styles[active ? 'active' : 'default'],
    styles[align],
    justified && styles.justified,
    removable && styles.removable,
    (loading || loadingState) && styles.loading,
  );

  const [loadingState, setLoadingState] = useState(false);

  const isMounted = useIsMounted();

  const containerRef = useRef(null);

  const onClicked = async (event) => {
    event.preventDefault();
    try {
      setLoadingState(!disableSpinner);
      await onClick();
    } finally {
      if (isMounted.current) setLoadingState(false);
    }
  };

  let appliedStyle = {
    ...style,
  };

  if ((loading || loadingState) && containerRef && containerRef.current) {
    appliedStyle['minWidth'] = `${containerRef.current.offsetWidth}px`;
  }

  return (
    <button
      type={submit ? 'submit' : 'button'}
      onClick={onClick && onClicked}
      className={buttonClassName}
      disabled={disabled || loadingState || loading}
      style={appliedStyle}
      {...props}
      ref={containerRef}>
      {loadingState || loading ? (
        <Loader color="#333" size={type === 'primary' ? 40 : 32} />
      ) : (
        <>
          {buttonBody(body, type, iconSrc, iconStyle)}
          {removable && removeIcon({onRemove})}
          {notificationOverlay(notificationText)}
        </>
      )}
    </button>
  );
};

ActionButton.propTypes = {
  active: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  body: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableSpinner: PropTypes.bool,
  iconSrc: iconSrcPropType,
  iconStyle: PropTypes.string,
  justified: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  removable: PropTypes.bool,
  style: PropTypes.object,
  submit: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary', 'chip', 'text', 'icon']),
  notificationText: PropTypes.any,
  _: (props) =>
    !props.body &&
    !props.iconSrc &&
    new Error('Most provide either body or iconSrc'),
};

ActionButton.defaultProps = {
  active: false,
  align: 'center',
  disabled: false,
  disableSpinner: false,
  justified: false,
  onRemove: () => {},
  removable: false,
  style: {},
  submit: false,
  type: 'secondary',
};

export default ActionButton;
