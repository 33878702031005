import React, {Component} from 'react';
import {connect} from 'react-redux';
import Title from '../components/Title';
import Logo from '@shared/components/Logo';
import {withTranslator} from '@shared/components/wrappers';
import validate from 'validate.js';
import {emailConstraints} from '@shared/utilities/validation';
import NotFound404Page from '@shared/components/NotFound404Page';
import Input from '@shared/components/Input';
import ActionButton from '@shared/components/ActionButton';
import Text from '@shared/components/Text';
import Errors from '@shared/components/Errors';
import {enableConfirmationTokens} from '../app/selectors';
import {post} from '@shared/services/api';
import {withCta} from './CtaWrapper';

import frontendUrls from '@frontend/app/urls';
import urls from '../app/urls';
import styles from './Login.module.sass';

class ResendInstructions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errors: [],
      emailSent: false,
    };
  }

  onInputChange = (value) => {
    this.setState({
      email: value,
    });
  }

  handleValidationError(error) {
    const errors = Object.values(error).flat();
    this.setState({errors});
  }

  validateForm() {
    const {email} = this.state;
    return validate({email}, emailConstraints, { i18n: this.props.i18n });
  }

  onSendInstructions = () => {
    const errors = this.validateForm();
    if (errors) {
      return this.handleValidationError(errors);
    }

    const {email} = this.state;
    post(frontendUrls.api.sendConfirmation(),  {confirmation: {email}})
      .then(res => {
        this.setState({
          emailSent: true,
        });
      })
      .catch(err => {
        this.setState({
          errors: [this.props.i18n.t('errors.messages.sending_confirmation_instructions')]
        })
        console.log('error', err);
      })
  }

  goBack = () => {
    this.props.history.push(urls.auth.login());
  }

  onForgotPassword = () => {
    this.props.history.push(urls.auth.forgotPassword());
  }

  clearError = () => {
    this.setState({
      errors: [],
    });
  }

  renderForm() {
    const { email, errors } = this.state;
    const { i18n } = this.props;
    return (
      <form className={styles.form}>
        <Title text={i18n.t('auth.reset_password.resend_confirmation_instructions')} />
        <Input
          value={email}
          type="email"
          name="email"
          placeholder={i18n.t('auth.fields.email')}
          onChange={this.onInputChange}
          onFocus={this.clearError}
        />
        <Errors errors={errors} />
        <ActionButton
          active
          justified
          submit
          type="primary"
          body={i18n.t('auth.reset_password.send_confirmation_instructions')}
          onClick={this.onSendInstructions}
          style={{marginTop: 24}}
          disabled={email === ''}
        />
        <ActionButton
          justified
          type="text"
          body={i18n.t('auth.sign_in.button')}
          onClick={this.goBack}
        />
        <ActionButton
          justified
          type="text"
          body={i18n.t('auth.reset_password.forgot_password')}
          onClick={this.onForgotPassword}
        />
      </form>
    );
  }

  renderSuccess() {
    const { email } = this.state;
    const { i18n } = this.props;
    return (
      <div className={styles.response}>
        <Title text={i18n.t('main_nav.success')} />
        <Text color="#808080">{i18n.t('auth.reset_password.confirmation_instructions_sent', { email })}</Text>
        <ActionButton
          justified
          active
          type="primary"
          body={i18n.t('auth.sign_in.back_to')}
          onClick={this.goBack}
        />
      </div>
    );
  }

  render() {
    const {confirmationTokensEnabled} = this.props;
    const {emailSent} = this.state;

    if (confirmationTokensEnabled) {
      return (
        <div className={styles.container}>
          <Logo />
          {emailSent ? this.renderSuccess() : this.renderForm()}
        </div>
      );
    } else {
      return <NotFound404Page/>
    }
  }
}

const mapStateToProps = (state) => ({
  confirmationTokensEnabled: enableConfirmationTokens(state),
});

export default connect(mapStateToProps)(withCta(withTranslator(ResendInstructions)));
