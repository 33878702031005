
// Currently using XHR because the fetch API does not support upload progress
export const uploadFile = (url, file, options = {}) => {
    const { method = 'POST', onProgress, name = 'file' } = options;

    const formData = new FormData();
    formData.append(name, file);

    const xhr = new XMLHttpRequest();
    xhr.open(method.toUpperCase(), url);
    xhr.send(formData);

    if (onProgress)
        xhr.upload.onprogress = onProgress;

    return new Promise(function (resolve, reject) {
        xhr.onload  = () => { resolve(xhr); };
        xhr.onerror = () => { reject(xhr); };
    } );
};
