export const emailConstraints = {
  email: {
    presence: {
      allowEmpty: false,
    },
    email: {
      message: (a, b, c, d, opts) => opts.i18n.t('auth.validation.email'),
    },
  },
};

export const genericConstraints = ({
  displayName,
  allowEmpty,
  message,
  ...props
}) => ({
  displayName,
  presence: {
    allowEmpty: allowEmpty || false,
    message: () => message || '',
  },
  ...props,
});

export const emailConstraintProp = {
  email: {
    message: (a, b, c, d, opts) => opts.i18n.t('auth.validation.email'),
  },
};

export const stripeConstraintProp = {
  inclusion: {
    within: ['valid'],
    message: '^%{value}', // use the value of the card as the error message
  },
};

export const phoneNumberConstraintProp = {
  format: {
    pattern: '\\(?\\d{3}(\\)\\s?|-|\\s)\\d{3}-\\d{4}',
  },
};

export const zipCodeConstraintProp = {
  format: {
    pattern: '\\d{5}',
  },
};
