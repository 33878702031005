import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styles from './ProfileImage.module.sass';
import colors from '@shared/helpers/colors';
import CameraIcon from '@shared/images/camera.svg';

class ProfileImage extends Component {
  constructor(props) {
    super(props);
    this.fallbackImageUrl = '/images/fallback-profile-image.png';
    this.state = {
      src: this.props.src || this.fallbackImageUrl,
      file: null,
    };
    this.fileReader = new FileReader();
    this.fileReader.onload = this.onFileLoaded;
  }

  fallbackImage = () => {
    this.setState({src: this.fallbackImageUrl});
  };

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.setState({src: this.props.src});
    }
  }

  uploadImage = () => {
    this.fileUploadRef.click();
  };

  onFileLoaded = (event) => {
    const {result} = event.target;
    this.setState({
      src: result,
    });
    this.props.onImageChange(this.state.file);
  };

  fileUploadChange = (event) => {
    const file = event.target.files[0];
    this.setState({file});
    this.fileReader.readAsDataURL(file);
  };

  render() {
    const {center, wrapperStyle, updatable} = this.props;

    return (
      <div
        className={`${styles.container} ${center && styles.center}`}
        style={wrapperStyle}>
        <img
          className={styles.image}
          src={this.state.src}
          onError={this.fallbackImage}
        />
        {updatable && (
          <label
            onClick={this.uploadImage}
            htmlFor="profile-image-file-upload"
            className={styles.cameraContainer}>
            <input
              ref={(el) => {
                this.fileUploadRef = el;
              }}
              multiple={false}
              style={{display: 'none'}}
              type="file"
              name="profile-image-file-upload"
              onChange={this.fileUploadChange}
            />
            <CameraIcon color={colors.iconGrey} />
          </label>
        )}
      </div>
    );
  }
}

ProfileImage.defaultProps = {
  updatable: false,
  center: true,
  wrapperStyle: {},
  imageStyle: {},
  onImageChange: () => {},
};

ProfileImage.propTypes = {
  center: PropTypes.bool,
  updatable: PropTypes.bool,
  src: PropTypes.string.isRequired,
  onImageChange: PropTypes.func,
  wrapperStyle: PropTypes.object,
  imageStyle: PropTypes.object,
};

export default ProfileImage;
