import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckIcon from '@shared/images/check.svg';

import styles from './Checkbox.module.sass';

const Checkbox = ({
  onClick,
  value,
  disabled,
  checked,
  inverse,
  wrapperStyle,
  checkStyle,
  className,
  ...props
}) => (
  <div
    className={classNames(
      styles.container,
      disabled && styles.disabled,
      checked && styles.checked,
      inverse && styles.inverse,
    )}
    style={wrapperStyle}
    onClick={() => !disabled && onClick()}>
    <input
      className={styles.input}
      type="checkbox"
      value={value?.toString()}
      disabled={disabled}
      {...props}
    />
    <CheckIcon
      color={inverse ? '#0088FD' : '#FFFFFF'}
      className={classNames(styles.checkIcon, className)}
      style={checkStyle}
    />
  </div>
);

Checkbox.defaultProps = {
  inverse: false,
  checked: false,
  disabled: false,
  checkStyle: {},
  wrapperStyle: {},
  onClick: () => {},
};

Checkbox.propTypes = {
  name: PropTypes.string,
  inverse: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Checkbox;
