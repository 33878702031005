import querystring from 'query-string';

const apiUrl = '/api';

const fullUrl = (path, params, options) => {
  let rootUrl = apiUrl;
  if (window.app_options?.subdomain && window.app_options?.domain_suffix) {
    rootUrl = `https://${window.app_options.subdomain}.${window.app_options.domain_suffix}${rootUrl}`;
  }
  return buildUrl(rootUrl, path, params, options);
};

export const buildUrl = (base, path, params, options) => {
  let baseUrl = `${base}${path}`;
  const queryString = params && querystring.stringify(params, options);
  if (queryString?.length) {
    if (baseUrl.includes('?')) baseUrl = `${baseUrl}&${queryString}`;
    else baseUrl = `${baseUrl}?${queryString}`;
  }

  return baseUrl;
};

export const concatUrlPaths = (...paths) =>
  paths
    .filter((p) => p || p === 0)
    .reduce(
      (url, path) =>
        url && url[url.length - 1] !== '/'
          ? url.concat(`/${path}`)
          : url.concat(path),
      '',
    );

const urls = {
  api: {
    register: () => fullUrl('/registration'),
    login: () => fullUrl('/session'),
    setupOtp: () => fullUrl('/session/setup_otp'),
    resendOtp: () => fullUrl('/session/resend_otp'),
    requestResetPassword: () => `${apiUrl}/passwords`,
    config: () => fullUrl('/config'),
    profile: () => fullUrl('/profile'),
    technologies: () => fullUrl('/preferred_technologies'),
    skills: (q) => fullUrl('/skills', {q}),
    skill: (id) => fullUrl(`/skills/${id}`),
    onboardingSkills: () => fullUrl('/skills', {onboarding: true}),
    sessionKeepAlive: () => fullUrl('/session/keep_alive'),
    like: () => fullUrl('/votes'),
    unlike: (id) => fullUrl(`/votes/${id}`),
    question_comments: (id, type) =>
      fullUrl(`/questions/${id}/comments`, {type}),
    schedules: (params) => fullUrl('/schedules', params),
    schedule: (id) => fullUrl(`/schedules/${id}`),
    meetings: (params) => fullUrl('/meetings', params),
    meetingPayment: (id) => fullUrl(`/meetings/${id}/payment`),
    formResponses: (formId) => fullUrl(`/forms/${formId}/responses`),
    formListResponses: (formListId) =>
      fullUrl(`/form_lists/${formListId}/responses`),
    formResponse: (formResponseId, formId) =>
      fullUrl(`/forms/${formId}/responses/${formResponseId}`),
    formListResponse: (formResponseId, formListId) =>
      fullUrl(`/form_lists/${formListId}/responses/${formResponseId}`),
  },
};

export default urls;
