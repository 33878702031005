import {get} from '@shared/services/api';
import urls from '@shared/app/urls';

export const SKILLS_FETCHED = 'SKILLS_FETCHED';
export const ONBOARDING_SKILLS_FETCHED = 'ONBOARDING_SKILLS_FETCHED';

const skillsFetched = skills => ({
  type: SKILLS_FETCHED,
  skills,
});

const onboardingSkillsFetched = onboardingSkills => ({
  type: ONBOARDING_SKILLS_FETCHED,
  onboardingSkills,
})

export const fetchSkills = (q) => async dispatch => {
  try {
    const data = await get(urls.api.skills());
    dispatch(skillsFetched(data));
  } catch(err) {
    return Promise.reject(err);
  }
}

export const fetchOnboardingSkills = () => async dispatch => {
  try {
    const data = await get(urls.api.onboardingSkills());
    dispatch(onboardingSkillsFetched(data));
  } catch(err) {
    return Promise.reject(err);
  }
}
