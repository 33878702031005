import querystring from 'query-string';
import HttpError from '@shared/errors/http_error';
import {encode} from 'base-64';
import {buildUrlPrefix} from '@shared/utilities/url';

const defaultResolveFn = (resolve, res) => resolve(res);

const NO_CONTENT = 204;
const UNAUTHORIZED = 401;

let baseUri = undefined;
let authorizationHeader = undefined;

const fetchJson = (
  url,
  options,
  headerOptions,
  resolveFn = defaultResolveFn,
) => {
  options = processOptions(options, headerOptions);
  url = processUrl(url, options);
  //plog('Calling API:', url, options);

  return new Promise((resolve, reject) =>
    fetch(url, options)
      .then(checkStatus)
      .then(extractAuthorizationHeader)
      .then(toJson)
      .then((res) => resolveFn(resolve, res))
      .catch((error) => {
        logFetchError(error, url, options);
        reject(error);
      }),
  );
};

const checkStatus = (response) => {
  const {status, handled} = response;
  if (handled || (status >= 200 && status < 300)) return response;

  return new Promise((resolve, reject) => {
    response
      .json()
      .then((json) => {
        const message = parseJsonError(json);
        const error = new HttpError(status, message, response);
        error.handled = handled;
        reject(error);
      })
      .catch((error) => {
        const error2 = new HttpError(status, response.statusText, response);
        error2.handled = handled;
        reject(error2);
      });
  });
};

const extractAuthorizationHeader = (response) => {
  if (response.headers.has('Authorization')) {
    authorizationHeader = response.headers.get('Authorization');
  }

  return response;
}

const parseJsonError = (jsonError) => {
  const json = JSON.parse(JSON.stringify(jsonError));
  if (Array.isArray(json)) {
    return json.join(', ');
  } else {
    return json;
  }
};

const logFetchError = (error, url, options) => {
  if (
    options?.authorizing &&
    error.response &&
    error.response.status == UNAUTHORIZED
  )
    return;

  console.error(`Error fetching ${url}`, error);
  error.logged = true;
};

const processHeaders = (options, headerOptions) => {
  const csrfToken = document.querySelector(
    'head meta[name=csrf-token]',
  )?.content;
  const csrfHeader = csrfToken && {'X-CSRF-TOKEN': csrfToken};

  const authHeader = authorizationHeader && {'Authorization': authorizationHeader};

  return {
    ...csrfHeader,
    ...authHeader,
    ...(options.headers ?? {}),
    ...(headerOptions ?? {credentials: 'same-origin'}),
  };
};

export const processOptions = (options, headerOptions) => {
  const opts = {
    ...options,
    headers: processHeaders(options, headerOptions),
  };

  if (opts.body instanceof FormData) {
    delete opts.headers['Content-Type'];
  } else if (opts.body && typeof opts.body === 'object') {
    opts.body = JSON.stringify(opts.body);
    opts.headers['Content-Type'] =
      opts.headers['Content-Type'] || 'application/json';
  }

  return opts;
};

export const processUrl = (url, options) => {
  if (baseUri) {
    return `${baseUri}${url}`;
  } else {
    return url;
  }
}

export const setBaseUri = (uri) => {
  if (!uri) {
    baseUri = undefined;
  } else if (uri.match('://')) { // full-uri
    baseUri = uri;
  } else if (uri.match('.') && uri.match('.')[0] === '.') { // full-domain
    baseUri = `https://${uri}`;
  } else { // subdomain only
    baseUri = buildUrlPrefix(uri);
  }
}

const toJson = (response) => {
  const {status} = response;
  if (status === NO_CONTENT) {
    return null;
  } else {
    return response.json();
  }
};

const reasonFromError = (error) => {
  if (!error) return;

  if (error.message) return error.message;

  if (error.errors) {
    const reason = Array.values(error.errors).join(', ');
    if (reason) return reason;
  }

  if (error.reason) return error.reason;
};

export const reasonFromResponse = async (response) => {
  try {
    const json = await response.json();

    const reason = reasonFromError(json.error) || response.statusText;
    return [reason, json];
  } catch (ex) {
    return [response.statusText, undefined];
  }
};

export const get = (
  url,
  params = {},
  options = {},
  resolveFn = defaultResolveFn,
) => {
  const queryString = params && querystring.stringify(params, options);
  if (queryString.length) {
    if (url.includes('?')) url = `${url}&${queryString}`;
    else url = `${url}?${queryString}`;
  }

  return fetchJson(url, {method: 'GET', ...options}, {}, resolveFn);
};

export const post = (url, data, options = {}, resolveFn = defaultResolveFn) =>
  fetchJson(url, {method: 'POST', body: data, ...options}, {}, resolveFn);

export const put = (url, data, options = {}, resolveFn = defaultResolveFn) =>
  fetchJson(url, {method: 'PUT', body: data, ...options}, {}, resolveFn);

export const patch = (url, data, options = {}, resolveFn = defaultResolveFn) =>
  fetchJson(url, {method: 'PATCH', body: data, ...options}, {}, resolveFn);

export const del = (url, data, options = {}, resolveFn = defaultResolveFn) =>
  fetchJson(url, {method: 'DELETE', body: data, ...options}, {}, resolveFn);

export const authenticationHeader = (email, password) => ({
  Authorization: `Basic ${encode(`${email}:${password}`)}`,
});
