import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import VisibilityToggle from '@shared/images/visibility-toggle.svg';
import SearchIcon from '@shared/images/search.svg';

import styles from './InputField.module.sass';

const InputField = ({
  field,
  form: {touched, dirty, errors, setErrors, setFieldValue, isValid}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  hasLabel,
  style,
  containerStyle,
  type,
  onFocus,
  customOnChange,
  leftComponent,
  rightComponent,
  innerRef,
  ...props
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isPassword = type === 'password';
  const inputType = isPassword && inputVisible ? 'text' : type;
  const displayLabel = hasLabel && props.placeholder && field.value !== '';
  const hasError = touched[field.name] && dirty && errors[field.name];

  const inputClassName = classNames(
    styles.input,
    hasError && styles.error,
    leftComponent && styles.hasLeftIcon,
    rightComponent && styles.hasRightIcon,
  );

  const labelClassName = classNames(
    styles.label,
    leftComponent && styles.hasLeftIcon,
  );

  const handleFocus = useCallback(
    (e) => {
      setIsFocused(true);
      if (onFocus && typeof onFocus === 'function') {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const handleChange = useCallback(
    (e) => {
      if (customOnChange && typeof customOnChange === 'function') {
        customOnChange;
      }
      field.onChange(e);
    },
    [customOnChange],
  );

  return (
    <div className={styles.container} style={containerStyle}>
      {displayLabel && (
        <label htmlFor={name} className={labelClassName}>
          {props.placeholder}
        </label>
      )}
      {leftComponent && (
        <div className={styles.leftIconContainer}>{leftComponent}</div>
      )}
      {type === 'search' && <SearchIcon className={styles.searchIcon} />}
      <input
        className={inputClassName}
        style={style}
        {...field}
        {...props}
        onChange={handleChange}
        onFocus={handleFocus}
        type={inputType}
        ref={innerRef}
      />
      {isPassword && (
        <div
          className={styles.visibilityToggle}
          onClick={() => setInputVisible(!inputVisible)}>
          <VisibilityToggle
            opacity={inputVisible ? 1 : 0.3}
            color={inputVisible ? '#0088FD' : '#333333'}
          />
        </div>
      )}
      {rightComponent && !isPassword && (
        <div className={styles.rightSideIcon}>{rightComponent}</div>
      )}
    </div>
  );
};

InputField.propTypes = {
  containerStyle: PropTypes.object,
  customOnChange: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object,
    setFieldValue: PropTypes.func,
  }).isRequired,
  hasLabel: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  rightComponent: PropTypes.element,
  leftComponent: PropTypes.element,
  innerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

InputField.defaultProps = {
  type: 'text',
  style: {},
  containerStyle: {},
  hasLabel: true,
};

export default InputField;
