import {buildUrl, concatUrlPaths} from '@shared/app/urls';

const apiUrl = '/api';

const urls = {
  frontend: {
    settings: () => '/settings',
    adminSettings: () => '/admin/settings/dashboard',
    support: () => '/support',
    signOut: () => '/users/sign_out',
    sso_signup: () => '/sso_signup',
    inquiries: () => '/inquiries',
    inquiry: (id) => `/inquiries/${id}`,
    inquiryPayment: (id) => `/inquiries/${id}/payment`,
    inquiryConversation: (inquiryId, id) =>
      `/inquiries/${inquiryId}/conversations/${id}`,
    interactions: () => '/interactions',
    interaction: (id) => `/interactions/${id}`,
    patients: () => '/patients',
    patient: (id) => `/patients/${id}`,
    patientConversation: (patientId, id) =>
      `/patients/${patientId}/conversations/${id}`,
    appointments: () => '/appointments',
    appointment: (id) => `/appointments/${id}`,
    content: () => '/content',
    konversations: () => '/konversations',
    konversation: (id) => `/konversations/${id}`,
    konversationSmartlink: (id, chatLineId) =>
      `/konversations/archives/${id}/${chatLineId}`,
    newKonversation: () => '/konversations/new',
    oneContent: (id) => `/content/${id}`,
    conversations: () => '/conversations',
    newContent: () => '/content/new',
    editContent: (id) => `/content/${id}/edit`,
    contentMembers: (id) => `/content/${id}/members`,
    contentMember: (id, memberId) => `/content/${id}/member/${memberId}`,
    contentSection: (id, sectionId) => `/content/${id}/section/${sectionId}`,
    knowledgeTaps: () => '/knowledgetaps',
    newKnowledgeTap: () => '/knowledgetaps/new',
    knowledgeTap: (id) => `/knowledgetaps/${id}`,
    knowledgeTapFull: (id) => `/knowledgetaps/f/${id}`,
    editKnowledgeTap: (id) => `/knowledgetaps/${id}/edit`,
    meeting: (token) => `/meetings/${token}`,
    workstreams: () => '/workstreams',
    home: () => '/',
    calendar: (view, year, month, day) =>
      concatUrlPaths('/calendar', view, year, month, day),
    newSchedule: () => '/calendar/new',
    chatAutomationSettings: () => '/admin/settings/chat_automation',
    editSchedule: (id) => `/calendar/${id}/edit`,
  },
  api: {
    register: () => `${apiUrl}/registration`,
    login: () => `${apiUrl}/session`,
    requestResetPassword: () => `${apiUrl}/passwords`,
    sendConfirmation: () => `${apiUrl}/user/confirmation_token/create`,
    interests: () => `${apiUrl}/interests`,
    skills: () => `${apiUrl}/skills`,
    profile: () => `${apiUrl}/profile`,
    agents: () => `${apiUrl}/agents`,
    inquiries: (params) => buildUrl(apiUrl, '/expert_chats', params),
    inquiryCount: (type) =>
      buildUrl(apiUrl, '/expert_chats', {type, count_only: true}),
    inquiry: (id) => `${apiUrl}/expert_chats/${id}`,
    interactions: (params) => buildUrl(apiUrl, '/interactions', params),
    interactionCount: (type) =>
      buildUrl(apiUrl, '/interactions', {type, count_only: true}),
    interaction: (id) => `${apiUrl}/interactions/${id}`,
    interactionMessages: (id) => `${apiUrl}/interactions/${id}/messages`,
    experts: () => `${apiUrl}/experts`,
    inquiryHistory: (id) => `${apiUrl}/expert_chats/${id}/history`,
    inquiryNotes: (id) => `${apiUrl}/expert_chats/${id}/notes`,
    // eslint-disable-next-line camelcase
    patients: ({page, member_ids}) =>
      buildUrl(
        apiUrl,
        '/members',
        {page, member_ids, for_provider: true},
        {arrayFormat: 'bracket'},
      ),
    patient: (id) => `${apiUrl}/members/${id}`,
    patientData: (id) => `${apiUrl}/members/${id}/data`,
    content: (ids) =>
      buildUrl(apiUrl, '/courses', {ids}, {arrayFormat: 'bracket'}),
    filteredContent: (keywords) =>
      buildUrl(apiUrl, '/courses', {keyword_filter: keywords}),
    oneContent: (id) => `${apiUrl}/courses/${id}`,
    completeSection: (id) => `${apiUrl}/sections/${id}/completed`,
    template: (id) => `${apiUrl}/courses/${id}/template`,
    contentResponses: (id) => `${apiUrl}/courses/${id}/responses`,
    contentResponsesForMember: (id, memberId) =>
      `${apiUrl}/courses/${id}/responses/${memberId}`,
    conversationSuggestedMembers: () =>
      `${apiUrl}/conversations/suggested_members`,
    knowledgeTaps: () => `${apiUrl}/questions`,
    knowledgeTapSuggestion: () => `${apiUrl}/questions/suggestions`,
    knowledgeTap: (id) => `${apiUrl}/questions/${id}`,
    knowledgeTapsBulk: (ids) =>
      buildUrl(apiUrl, '/questions', {ids}, {arrayFormat: 'bracket'}),
    members: () => `${apiUrl}/members`,
    conversation: (id) => `${apiUrl}/conversations/${id}`,
    conversationMembers: (conversationId) =>
      buildUrl(apiUrl, '/members', {conversation_id: conversationId}),
    conversationChatLines: (conversationId) =>
      `${apiUrl}/conversations/${conversationId}/chat_lines`,
    flagMessage: (messageId, conversationId) =>
      `${apiUrl}/conversations/${conversationId}/chat_lines/${messageId}`,
    markUnread: (conversationId, memberId) =>
      `${apiUrl}/conversations/${conversationId}/members/${memberId}`,
    muteConversation: (conversationId, memberId) =>
      `${apiUrl}/conversations/${conversationId}/members/${memberId}`,
    chatLine: (conversationId) =>
      `${apiUrl}/conversations/${conversationId}/chat_lines/`,
    updateMemberSubscriptions: () => `${apiUrl}/subscriptions/bulk`,
    linkInfo: (url) => buildUrl(apiUrl, '/link_infos', {url}),
    meetingRoomRecording: (token, archive_id) =>
      buildUrl(apiUrl, `/meeting_rooms/${token}/recording`, {archive_id}),
  },
};

export default urls;
