
const UNAUTHORIZED = 401;

let interceptor = undefined;
let originalFetch;

const isBraidioApiCall = response => {
    return response.url.startsWith(window.location.origin);
}

const checkForUnauthorized = (response, request, options) => {
    if (response.status === UNAUTHORIZED && isBraidioApiCall(response) && interceptor) {
        response.handled = true;
        if (!options?.authorizing)
            interceptor({response, request, options});
    }
}

const fetchWithIntercept = (resource, options, ...args) => {
    const request = (resource instanceof Request) ? resource : new Request(resource, options, ...args);

    return originalFetch(request)
        .then(response => {
            checkForUnauthorized(response, request, options);
            return response;
        });
}

const replaceFetch = () => {
    if (typeof window === 'undefined')
        throw new Error('`window` global not defined');
    if (!window.fetch)
        throw new Error('Window does not supprt the `fetch` API');

    //  This is more complicated than needed because we have duplicate modules (this file is included in admin and admin-bundle)
    if (window.fetch.intercepted) {
        if (window.fetch === fetchWithIntercept && originalFetch)
            // We intercepted ourselves
            return;

        // There is a duplicate module
        console.warn("Fetch already intercepted. The original will be removed");
        const original = window.fetch?.original || originalFetch;
        if (!original)
            throw new Error('Unable to resolve original fetch function');
        window.fetch = original;
    }

    //console.log('REPLACING FETCH HANDLER');
    originalFetch = window.fetch;
    window.fetch  = fetchWithIntercept;
    window.fetch.intercepted = true;
    window.fetch.original    = originalFetch;
}

const setUnauthorizedRequestInterceptor = (requestInterceptor) => {
    interceptor = requestInterceptor;
    replaceFetch();
}

export { setUnauthorizedRequestInterceptor };
