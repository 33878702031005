import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import validate from 'validate.js';
import {Formik, Form, Field} from 'formik';

import {emailConstraints} from '@shared/utilities/validation';
import {getLoginCredentials} from '@shared/session/selectors';
import {requestResetPassword} from '@shared/session/actions';

import {withTranslator} from '@shared/components/wrappers';
import Errors from '@shared/components/Errors';
import ActionButton from '@shared/components/ActionButton';
import Text from '@shared/components/Text';
import HtmlContent from '@shared/components/HtmlContent';
import EmailField from '@shared/components/formik/EmailField';
import Logo from '@shared/components/Logo';
import Title from '../components/Title';
import {enableConfirmationTokens} from '../app/selectors';
import {withCta} from './CtaWrapper';

import urls from '../app/urls';
import styles from './ForgotPassword.module.sass';

const ForgotPassword = ({history, i18n}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const loginCredentials = useSelector(getLoginCredentials);
  const confirmationTokensEnabled = useSelector(enableConfirmationTokens);

  const [emailSent, setEmailSent] = useState('');
  const [serverErrors, setServerErrors] = useState([]);
  const [expiredFlow, setExpiredFlow] = useState(!!urlParams.get('reset'));

  const clearError = () => {
    setServerErrors([]);
  };

  const validateForm = ({email}) => validate({email}, emailConstraints, {i18n});

  const onSendInstructions = async ({email}) => {
    try {
      await dispatch(requestResetPassword(email));
      setEmailSent(email);
    } catch (err) {
      console.log('onSendInstructions Error', err);
      setServerErrors([err.message]);
    }
  };

  const onNeedInstructions = () => {
    history.push(urls.auth.sendConfirmationInstructions());
  };

  const goBack = () => {
    history.push(urls.auth.login());
  };

  const initialEmailValue = expiredFlow && loginCredentials?.email;

  const renderForm = () => (
    <Formik
      initialValues={{email: initialEmailValue || ''}}
      validate={validateForm}
      validateOnMount
      onSubmit={onSendInstructions}>
      {({errors, handleSubmit, isValid, dirty, isSubmitting}) => (
        <Form className={styles.form}>
          <Title
            text={i18n.t(
              expiredFlow
                ? 'auth.reset_password.reset_password'
                : 'auth.reset_password.forgot_password',
            )}
          />
          <Field
            component={EmailField}
            name="email"
            placeholder={i18n.t('auth.fields.email')}
            customOnChange={clearError}
          />
          {dirty && <Errors errors={errors} />}
          {serverErrors.length > 0 && <Errors errors={serverErrors} />}
          <div className={styles.actions}>
            <ActionButton
              type="primary"
              justified
              active
              body={i18n.t('auth.reset_password.send_reset_instructions')}
              loading={isSubmitting}
              submit
              disabled={!isValid}
              onClick={handleSubmit}
            />
            {confirmationTokensEnabled && <ActionButton
              type="text"
              body={i18n.t(
                'auth.reset_password.need_confirmation_instructions',
              )}
              onClick={onNeedInstructions}
            />}
            <ActionButton
              type="text"
              body={i18n.t('auth.sign_in.button')}
              onClick={goBack}
            />
          </div>
        </Form>
      )}
    </Formik>
  );

  const renderSuccess = () => (
    <div className={styles.response}>
      <Title text={i18n.t('main_nav.success')} />
      <Text color="#808080">
        <HtmlContent
          content={i18n.t('auth.reset_password.reset_instructions_sent_html', {
            email: emailSent,
          })}
        />
      </Text>
      <ActionButton
        justified
        active
        type="primary"
        body={i18n.t('auth.sign_in.back_to')}
        onClick={goBack}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      <Logo />
      {emailSent !== '' ? renderSuccess() : renderForm()}
    </div>
  );
};

export default withCta(withTranslator(ForgotPassword));
