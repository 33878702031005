import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.sass';
import Text from '@shared/components/Text';
import Checkbox from '@shared/components/Checkbox';

const getTextColor = ({selected, disabled}) => {
  if (disabled || selected) {
    return "#FFFFFF";
  }
  return "#333333";
}

const getClassNames = ({selected, disabled, selectable}) => {
  let classNames = styles.container;
  if (selected) {
    classNames += ` ${styles.selected}`;
  }

  if (selectable) {
    classNames += ` ${styles.selectable}`;
  }

  if (disabled) {
    classNames += ` ${styles.disabled}`;
  }

  return classNames;
}

function Card(props) {
  const {
    onClick,
    title,
    content,
    disabled,
    selectable,
    selected,
    style,
  } = props;

  const textColor = getTextColor(props);
  const classNames = getClassNames(props)
  return (
    <div
      className={classNames}
      style={style}
      onClick={() => !disabled && onClick()}
    >
      {(selectable && selected) && (
        <div className={styles.checkboxWrapper}>
          <Checkbox checked={selected} inverse />
        </div>
        )}
      {content}
      <Text type="button" tag="span" color={textColor}>{title}</Text>
    </div>
  );
};

Card.defaultProps = {
  selectable: false,
  disabled: false,
  selected: false,
  onClick: () => {},
};

Card.propTypes = {
  selectable: PropTypes.bool,
  content: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
};

export default Card;
