import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslator } from '@shared/hooks';
import { defaultUnauthorizedHandler } from '../unauthorized_request_handlers';

import styles from '@shared/popups/Popup.module.sass';

//#@todo: Replace this with code that starts the new SPA login forms

export const startLogin = (reason) => async (dispatch) => {
    defaultUnauthorizedHandler({reason});

    return Promise.resolve();
};

function UnauthorizedPopup({reason}) {
  const i18n = useTranslator();
  const dispatch = useDispatch();
  return (
    <div className={styles.popup}>
      <h3 className={styles.title}>{i18n.t('dialogs.unauthorized.title')}</h3>
      <p className={styles.copy}>{reason}</p>
      <div className={styles.buttons}>
        <div className={styles.textButton} onClick={() => dispatch(startLogin(reason))}>Login</div>
      </div>
    </div>
  );
}

export default UnauthorizedPopup;
