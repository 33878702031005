import {actionTypes as ktActions} from '@frontend/knowledge_taps/actions';
import * as settingsActions from '@frontend/settings/actions';
import * as actions from './actions';

const initialState = {
  currentMember: {},
  loginCredentials: {
    email: '',
    password: '',
  },
  automatic_messages: {
    end_chat: {
      isLoading: false,
    },
    reminder: {
      isLoading: false,
    },
    welcome: {
      isLoading: false,
    },
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case actions.AUTHENTICATING:
      return {
        ...state,
        loginCredentials: action.credentials,
      };
    case actions.LOGGED_IN:
      return {
        ...state,
        currentMember: action.currentMember,
        loginCredentials: {
          email: '',
          password: '',
        },
      };
    case actions.CURRENT_MEMBER_ONLINE:
      return {
        ...state,
        currentMember: {
          ...state.currentMember,
          online: true,
        },
      };
    case actions.CURRENT_MEMBER_OFFLINE:
      return {
        ...state,
        currentMember: {
          ...state.currentMember,
          online: false,
        },
      };
    case ktActions.subscriptionsReceived:
      return {
        ...state,
        currentMember: {
          ...state.currentMember,
          subscriptions: action.subscriptions,
        },
      };
    case settingsActions.PATCH_AUTOMATIC_MESSAGES:
      return {
        ...state,
        automatic_messages: {
          ...state.automatic_messages,
          [action.optimistic.key]: {
            enabled: action.optimistic.enabled,
          },
          isLoading: true,
        },
      };

    case settingsActions.PATCH_AUTOMATIC_MESSAGES_SUCCEEDED:
      return {
        ...state,
        automatic_messages: {
          ...state.automatic_messages,
          ...action.automaticMessages,
          isLoading: false,
        },
      };

    case settingsActions.PATCH_AUTOMATIC_MESSAGES_FAILED:
      return {
        ...state,
        automatic_messages: {
          ...state.automatic_messages,
          [action.key]: {
            ...state.automatic_messages[action.key],
            enabled: action.backup,
          },
          isLoading: false,
        },
      };

    case actions.STRIPE_OPTIONS_RECEIVED:
      return {
        ...state,
        stripe: {
          ...state.stripe,
          options: {clientSecret: action.setupIntent.client_secret},
          setupIntent: action.setupIntent,
        },
      };

    default:
      return state;
  }
};

export default reducers;
