export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const OPTIONS_UPDATED = 'OPTIONS_UPDATED';
export const OPEN_FULLSCREEN_POPUP = 'OPEN_FULLSCREEN_POPUP';
export const CLOSE_FULLSCREEN_POPUP = 'CLOSE_FULLSCREEN_POPUP';
export const FULLSCREEN_OPTIONS_UPDATED = 'FULLSCREEN_OPTIONS_UPDATED';

export const openPopup = (component, options = {}) => ({
  type: OPEN_POPUP,
  component,
  options,
});

export const closePopup = () => ({
  type: CLOSE_POPUP,
});

export const updatePopupOptions = (options) => ({
  type: OPTIONS_UPDATED,
  options,
});

export const openFullScreenPopup = (
  fullScreenComponent,
  fullScreenOptions = {},
) => ({
  type: OPEN_FULLSCREEN_POPUP,
  fullScreenComponent,
  fullScreenOptions,
});

export const closeFullScreenPopup = () => ({
  type: CLOSE_FULLSCREEN_POPUP,
});

export const updateFullScreenPopupOptions = (fullScreenOptions) => ({
  type: FULLSCREEN_OPTIONS_UPDATED,
  fullScreenOptions,
});
