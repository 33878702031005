import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useTranslator} from '@shared/hooks';
import {closePopup} from '@shared/popups/actions';
import ActionButton from '@shared/components/ActionButton';
import Text from '@shared/components/Text';
import styles from '@shared/popups/Popup.module.sass';

const PasswordExpired = ({onReset}) => {
  const i18n = useTranslator();
  const dispatch = useDispatch();
  const sentences = i18n.t('devise.failure.password_expired').match(/([^\.!\?]+[\.!\?]+)|([^\.!\?]+$)/g);

  const title = sentences[0]
  const text = sentences[1];
  return (
    <div className={styles.popup}>
      <Text type="h5">{title}</Text>
      <Text>{text}</Text>
      <div className={styles.buttons}>
        <ActionButton
          style={{flex: 1, marginRight: 8}}
          body={i18n.t('system_buttons.cancel')}
          onClick={() => dispatch(closePopup())}
        />
        <ActionButton
          style={{flex: 1, marginLeft: 8}}
          active
          body={i18n.t('system_buttons.reset')}
          onClick={() => {
            onReset();
            dispatch(closePopup());
          }}
        />
      </div>
    </div>
  );
}

PasswordExpired.propTypes = {
  onReset: PropTypes.func,
};

PasswordExpired.defaultProps = {
  onReset: () => {},
}

export default PasswordExpired;
