import {createSelector} from '@reduxjs/toolkit';
import {createSelectorFromKey} from '@shared/utilities/selectors';
import urls from '../../../embed/app/urls';
import {getFormListById} from '../../form_lists/store/selectors';

const getRoot = (state) => state.forms;
const createRootSelector = (key) => createSelectorFromKey(getRoot, key);

export const getIsLoaded = createRootSelector('isLoaded');
export const getIsLoading = createRootSelector('isLoading');
export const getIsError = createRootSelector('isError');
export const getForms = createRootSelector('data');

export const getIsOrShouldLoad = createSelector(
  getIsLoading,
  getIsLoaded,
  (isLoading, isLoaded) => isLoading && !isLoaded,
);

export const getFormById = (id) =>
  createSelector(getForms, (forms) => forms.find((f) => f.id === id));

export const getFormBySlug = (slug) =>
  createSelector(getForms, (forms) => forms.find((form) => form.slug === slug));

export const getFormByIndex = (formListId, formIndex) =>
  createSelector(
    getFormListById(formListId),
    (state) => state,
    (formList, state) => getFormById(formList?.forms[formIndex]?.id)(state),
  );

export const getForm = ({formListId, formIndex, formId, slug}) => {
  if (formListId) {
    return getFormByIndex(formListId, formIndex);
  }
  if (formId) {
    return getFormById(formId);
  }
  if (slug) {
    return getFormBySlug(slug);
  }
  return createSelector(() => {});
};

export const getFormStepById = (formId, stepIndex) =>
  createSelector(getFormById(formId), (form) => form?.steps[stepIndex]);

export const getFormStepBySlug = (slug, stepIndex) =>
  createSelector(getFormBySlug(slug), (form) => form?.steps[stepIndex]);

export const getFormListStepByIndex = (formListId, formIndex, stepIndex) =>
  createSelector(
    getFormByIndex(formListId, formIndex),
    (form) => form?.steps && form.steps[stepIndex],
  );

export const getFormStep = ({
  formId,
  slug,
  formListId,
  formIndex,
  stepIndex,
}) => {
  if (formId) {
    return getFormStepById(formId, stepIndex);
  }
  if (slug) {
    return getFormStepBySlug({slug, stepIndex});
  }
  if (formListId) {
    return getFormListStepByIndex(formListId, formIndex, stepIndex);
  }
  return null;
};

export const getCanSkipFormStep = ({
  formId,
  slug,
  formListId,
  formIndex,
  stepIndex,
}) =>
  createSelector(
    getFormStep({
      formId,
      slug,
      formListId,
      formIndex,
      stepIndex,
    }),
    (step) => step?.fields.every((field) => !field.required),
  );

export const getNextFormStepPath = (formId, stepIndex) =>
  createSelector(
    getFormStepById(formId, stepIndex + 1),
    (nextStep) => nextStep && urls.embed.form(formId, stepIndex + 1),
  );

export const getPreviousFormStepPath = (formId, stepIndex) =>
  createSelector(
    getFormStepById(formId, stepIndex - 1),
    (previousStep) => previousStep && urls.embed.form(formId, stepIndex - 1),
  );

export const getNextFormListStepPath = (formListId, formIndex, stepIndex) =>
  createSelector(
    getFormListById(formListId),
    getFormByIndex(formListId, formIndex),
    getFormByIndex(formListId, formIndex + 1),
    getFormListStepByIndex(formListId, formIndex, stepIndex + 1),
    (formList, form, nextForm, nextStep) => {
      if (!formList || !form) return null;

      if (nextStep) {
        return urls.embed.formList(formList.id, formIndex, stepIndex + 1);
      }

      if (nextForm) {
        return urls.embed.formList(formList.id, formIndex + 1, 0);
      }
      return null;
    },
  );

export const getPreviousFormListStepPath = (formListId, formIndex, stepIndex) =>
  createSelector(
    getFormListById(formListId),
    getFormByIndex(formListId, formIndex),
    getFormByIndex(formListId, formIndex - 1),
    getFormListStepByIndex(formListId, formIndex, stepIndex - 1),
    (formList, form, previousForm, previousStep) => {
      if (!formList || !form) return null;

      if (previousStep) {
        return urls.embed.formList(formList.id, formIndex, stepIndex - 1);
      }

      if (previousForm) {
        return urls.embed.formList(
          formList.id,
          formIndex - 1,
          previousForm.steps?.length - 1,
        );
      }
      return null;
    },
  );

export const getNextStepPath = ({formListId, formId, formIndex, stepIndex}) => {
  if (formListId) {
    return getNextFormListStepPath(formListId, formIndex, stepIndex);
  }
  if (formId) {
    return getNextFormStepPath(formId, stepIndex);
  }
  return null;
};

export const getPreviousStepPath = ({
  formListId,
  formId,
  formIndex,
  stepIndex,
}) => {
  if (formListId) {
    return getPreviousFormListStepPath(formListId, formIndex, stepIndex);
  }
  if (formId) {
    return getPreviousFormStepPath(formId, stepIndex);
  }
  return null;
};
