import React from 'react';
// Don't use wrapper, it introduces a circular dependency
import withTranslator from '@shared/components/wrappers/withTranslator';
import {setTitle} from '@shared/utilities/title';

const NotFound404Page = ({i18n}) => {
  setTitle(i18n.t('main_nav.not_found_page.title'));

  return (
    <div className="admin_page">
      <h5>{i18n.t('main_nav.not_found_page.title')}</h5>
      <div className="app-content app-content--not-found py-4 px-4">
        {i18n.t('main_nav.not_found_page.content')}
      </div>
    </div>
  );
};

export default withTranslator(NotFound404Page);
