

export const logLevels = Object.freeze({
    debug:   0,
    info:    1,
    warn:    2,
    error:   3,
    fatal:   4,
    unknown: 5,
});

let logLevel = logLevels.debug;

export const initLoggingWithStore = (store) => {
    const env = store.getState().railsContext.railsEnv;

    logLevel = (env == 'development') ? logLevels.debug : logLevel.info;
};

export const log = (level, ...args) => {
  if (typeof(level) === 'string')
      level = logLevels[level] || level || 0;

  if (level > logLevel)
      return;

  const method = logLevel >   logLevels.warn ? 'error'
               : logLevel === logLevels.warn ? 'warn'
               :                               'log';

  console[method](...args);
};

export const debug   = (...args) => { log(0, ...args) };
export const info    = (...args) => { log(1, ...args) };
export const warn    = (...args) => { log(2, ...args) };
export const error   = (...args) => { log(3, ...args) };
export const fatal   = (...args) => { log(4, ...args) };
export const unknown = (...args) => { log(5, ...args) };

export const setLevel = (level = 0) => {
    if (typeof(level) === 'string')
        level = logLevels[level] || level || 0;

    logLevel = level;
};

export default {
    log,
    debug,
    info,
    warn,
    error,
    fatal,
    unknown,
    setLevel,
}

window.setLogLevel = setLevel;
