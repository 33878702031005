import React, {useState} from 'react';
import classNames from 'classnames';

import VisibilityToggle from '@shared/images/visibility-toggle.svg';
import SearchIcon from '@shared/images/search.svg';
import styles from './Input.module.sass';

export default function Input({
  value,
  placeholder,
  name,
  onChange,
  onFocus,
  onBlur = () => {},
  error = false,
  type = 'text',
  hasLabel = true,
  style,
  className = {},
  isSearch,
  isDisplayVisibilityToggle = true,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const [inputType, setInputType] = useState(type);
  const isVisibilityToggleActive = type === 'password' && inputType !== type;

  const inputClasses = classNames(
    className.input ?? styles.input,
    isSearch && (className.searchInput ?? styles.searchInput),
    error && (className.error ?? styles.error),
  );

  return (
    <div
      className={classNames(
        styles.container,
        className.container || className,
      )}>
      {hasLabel && value !== '' && (
        <label htmlFor={name} className={styles.label}>
          {placeholder}
        </label>
      )}
      {isSearch && <SearchIcon className={styles.searchIcon} />}
      <input
        className={inputClasses}
        name={name}
        placeholder={placeholder}
        style={style}
        value={value}
        onChange={(event) => {
          const {value} = event.target;
          onChange(value, event);
        }}
        onBlur={(event) => {
          const {value} = event.target;
          onBlur(value, event);
          setFocused(false);
        }}
        onFocus={() => {
          setFocused(true);
          if (onFocus && typeof onFocus === 'function') {
            onFocus();
          }
        }}
        type={inputType}
        {...props}
      />
      {type === 'password' && (
        <div
          className={classNames(
            styles.visibilityToggle,
            className.visibilityToggle,
          )}
          onClick={() => {
            const newInputType = isVisibilityToggleActive ? 'password' : 'text';
            setInputType(newInputType);
          }}>
          {isDisplayVisibilityToggle && (
            <VisibilityToggle
              opacity={isVisibilityToggleActive ? 1 : 0.3}
              color={isVisibilityToggleActive ? '#0088FD' : '#333333'}
            />
          )}
        </div>
      )}
    </div>
  );
}
