import {useEffect} from 'react';

const useFocusOnVisible = (visible, containerRef, selector) => {
  useEffect(() => {
    if (visible) {
        const focusable = containerRef.current?.querySelector(selector);
        focusable?.focus();
    }
  }, [visible]);
};

export default useFocusOnVisible;
