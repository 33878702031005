import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PinField from 'react-pin-field';

import {
  sendOtpCode,
  login,
  sendOtpPhoneNumber,
  resendCode,
  startAuthenticatorAppFlow,
} from '@shared/session/actions';
import {withTranslator} from '@shared/components/wrappers';
import ChevronDownIcon from '@shared/images/chevron-down.svg';
import {openPopup, closePopup} from '@shared/popups/actions';
import {getLoginCredentials} from '@shared/session/selectors';
import HttpError from '@shared/errors/http_error';
import urls from '../app/urls';

import Text from '@shared/components/Text';
import {COUNTRIES} from '@whatsapp/shared/helpers/constants';
import FlagSelect from '@shared/components/FlagSelect';
import ActionButton from '@shared/components/ActionButton';
import Errors from '@shared/components/Errors';
import PasswordExpiredPopup from '@auth/popups/PasswordExpired';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import styles from '@shared/popups/Popup.module.sass';

const ENTER_KEY = 13;

class TwoFactorVerification extends Component {
  steps = {
    phone: 'phone',
    code: 'sms',
    totp: 'totp',
  };

  constructor(props) {
    super(props);

    this.state = {
      code: '',
      errors: [],
      step: this.steps.phone,
      phoneNumber: '',
      data: {},
      disabled: false,
      country: null,
      countryPhone: null,
      is2FAMethodSelected: false,
    };

    this.wrapperRef = React.createRef();
    this.codeInputRef = React.createRef();
  }

  componentDidMount() {
    const {phoneVerified, data, isCloseOnOutsideClick, countryCode} = this.props;
    const country = COUNTRIES.find(c => c.code === (countryCode || 'US'))

    this.setState(
      {
        data,
        step: phoneVerified ? data.otp_type : this.steps.phone,
        country,
        countryPhone: '+' + country?.phone,
      },
      () => {
        if (phoneVerified) {
          this.focusCodeInput();
        }
      },
    );

    if (isCloseOnOutsideClick) {
      document.addEventListener('mousedown', this.closeOnOutSideClick);
    }

    document.addEventListener('keyup', this.onKeyUp, false);
  }

  componentWillUnmount() {
    const {isCloseOnOutsideClick} = this.props;
    if (isCloseOnOutsideClick) {
      document.removeEventListener('mousedown', this.closeOnOutSideClick);
    }

    document.removeEventListener('keyup', this.onKeyUp, false);
  }

  onKeyUp = (e) => {
    if (e.keyCode === ENTER_KEY) {
      this.onRegisterPhoneNumber();
    }
  };

  closeOnOutSideClick = (e) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(e.target)
    ) {
      this.props.closePopup();
    }
  };

  focusCodeInput() {
    if (this.codeInputRef && this.codeInputRef?.current.length) {
      this.codeInputRef.current[0].focus();
    }
  }

  onInputChange = (code) => {
    this.setState(
      {
        code,
        error: [],
      },
      () => {
        if (code.length === this.state.data?.digits) {
          this.sendCode();
        }
      },
    );
  };

  onPhoneNumberChange = (event) => {
    this.setState({
      phoneNumber: event.target.value,
    });
  };

  onCountryChange = (e, country) => {
    this.setState((currentState) => ({
      country,
      countryPhone: '+' + country.phone,
    }));
    this.toggleFlagSelectVisible();
  }

  async sendCode() {
    const {onAuthSuccess, phoneVerified, sendOtpCode, closePopup} = this.props;
    const {code} = this.state;
    const {phone_number} = this.state.data;
    const otp_type = this.state.step;
    try {
      const session = await sendOtpCode(code, phoneVerified, otp_type);
      onAuthSuccess(session);
      this.props.closePopup();
    } catch (err) {
      this.handleError(err, 'invalid_otp');
    }
  }

  onCancel = () => {
    const {closePopup, onCancel} = this.props;
    this.props.closePopup();
    if (onCancel && typeOf(onCancel) === 'function') {
      onCancel();
    }
  };

  onUseAuthenticator = async () => {
    const {startAuthenticatorAppFlow} = this.props;
    try {
      const data = await startAuthenticatorAppFlow();
      this.setState({
        data,
        step: this.steps.totp,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  onRegisterPhoneNumber = async () => {
    const {sendOtpPhoneNumber} = this.props;
    const {countryPhone, phoneNumber} = this.state;

    this.setState({is2FAMethodSelected: true});

    try {
      const data = await sendOtpPhoneNumber(`${countryPhone}${phoneNumber}`);
      this.setState({
        step: this.steps.code,
        data,
      });
    } catch (err) {
      this.handleError(err, 'invalid_phone');
    }
  };

  handleError(err, defaultError) {
    console.log('Error:', err.message);
    if (err instanceof HttpError) {
      const errData = err.message.error || {};
      const {code, otp_state} = errData;
      if (code === 401) {
        if (otp_state) {
          this.handleOtpError(errData);
        } else {
          this.handleAuthError(errData);
        }
      } else {
        this.setState({
          errors: [this.authErrorMessage(defaultError)],
        });
      }
    } else {
      this.setState({
        errors: [err.message],
      });
    }
  }

  handleOtpError(errorData) {
    const {otp_state, attempts_left} = errorData;

    switch (otp_state) {
      case 'failed':
        this.handleAttemptError(false, attempts_left);
        break;
      case 'too_many_attempts':
        this.handleAttemptError(true);
        break;
      case 'required':
        break;
      default:
        const errorMessage = this.authErrorMessage(otp_state);
        this.setState({
          errors: [errorMessage],
        });
    }
  }

  handleAuthError(errorData) {
    switch (errorData.error) {
      case 'password_expired':
        this.openPasswordExpiredPopup();
        break;
      default:
        const errorMessage = this.authErrorMessage(errorData.error);
        this.setState({
          errors: [errorMessage],
        });
    }
  }

  handleAttemptError(tooManyAttempts, attemptsLeft) {
    const {i18n} = this.props;
    let errorMessage;
    if (tooManyAttempts) {
      errorMessage = `${i18n.t(
        'devise.two_factor_authentication.max_login_attempts_reached',
      )}. ${i18n.t('devise.two_factor_authentication.contact_administrator')}`;
    } else if (attemptsLeft === 1) {
      errorMessage = `${i18n.t(
        'devise.two_factor_authentication.one_attempt_left',
      )}`;
    } else {
      errorMessage = `${i18n.t(
        'devise.two_factor_authentication.x_attempts_left',
        {attemptsLeft},
      )}`;
    }

    this.clearCodeInput();
    this.setState({
      errors: [errorMessage],
      disabled: tooManyAttempts,
    });
  }

  authErrorMessage(key) {
    const {i18n} = this.props;
    return i18n.t(`devise.failure.${key}`, {
      _: i18n.t('devise.failure.unknown'),
    });
  }

  openPasswordExpiredPopup() {
    this.props.closePopup();
    this.props.openPopup(
      <PasswordExpiredPopup onReset={() => this.onResetPassword()} />,
    );
  }

  onResendCode = async () => {
    const {phoneVerified, resendCode, i18n} = this.props;
    const {countryPhone, phoneNumber} = this.state;
    try {
      this.setState({
        code: '',
        errors: [i18n.t('devise.two_factor_authentication.code_has_been_sent')],
      });
      this.clearCodeInput();

      const data = await await resendCode(phoneVerified, `${countryPhone}${phoneNumber}`);
      this.setState({ data });

    } catch (err) {
      this.handleError(err, 'invalid_code');
    }
  };

  onResetPassword() {
    this.props.history.push(urls.auth.forgotPassword(true));
  }

  clearCodeInput() {
    // To reset the PIN field
    if (this.codeInputRef) {
      this.codeInputRef.current.forEach((input) => (input.value = ''));
      this.codeInputRef.current[0].focus();
    }
  }

  clearErrors = () => {
    this.setState({
      errors: [],
    });
  };

  toggleFlagSelectVisible = () =>
    this.setState((currentState) => (
      {
        ...currentState,
        isFlagSelectVisible: !currentState.isFlagSelectVisible,
      }
    ));

  renderDisabledView() {
    const {errors} = this.state;
    return (
      <div>
        <Text type="h5" className={styles.title}>
          2 Step Verification
        </Text>
        <Errors errors={errors} />
      </div>
    );
  }

  renderCodeView() {
    const {code, errors, digits, data, disabled} = this.state;
    const {i18n} = this.props;
    if (disabled) {
      return this.renderDisabledView();
    }
    return (
      <div>
        <Text type="h5" className={styles.title}>
          {i18n.t('devise.two_factor_authentication.title')}
        </Text>
        <Text>
          {i18n.t('devise.two_factor_authentication.label_direct', {
            phone: data?.phone_number,
          })}
        </Text>

        <div className={styles.codeInputContainer}>
          <PinField
            ref={this.codeInputRef}
            length={data?.digits || 6}
            validate="0123456789"
            className={styles.codeInput}
            onChange={this.onInputChange}
          />
        </div>
        <Errors errors={errors} />
        <ActionButton
          justified
          type="text"
          body={i18n.t('devise.two_factor_authentication.send_code_again')}
          align="right"
          style={{marginTop: 14}}
          onClick={this.onResendCode}
        />
        <ActionButton
          justified
          type="text"
          body={i18n.t(
            'devise.two_factor_authentication.setup.configure_totp_instead',
          )}
          align="right"
          onClick={this.onUseAuthenticator}
        />
      </div>
    );
  }

  renderCurrentStep() {
    const {step} = this.state;
    switch (step) {
      case this.steps.phone:
        return this.renderPhoneView();
      case this.steps.code:
        return this.renderCodeView();
      case this.steps.totp:
        return this.renderAuthenticatorView();
      default:
        return this.renderPhoneView();
    }
  }

  renderAuthenticatorView() {
    const {errors, data} = this.state;
    const {qr_code, otp_secret, otp_state} = data;
    const {i18n} = this.props;
    const content =
      otp_state === 'required' ? (
        <Text type="h5">
          {i18n.t('devise.two_factor_authentication.label_totp')}
        </Text>
      ) : (
        <>
          <Text type="h5">
            {i18n.t(
              'devise.two_factor_authentication.setup.label_totp_configuration',
            )}
          </Text>
          <svg dangerouslySetInnerHTML={{__html: qr_code}} />
          <Text>
            {i18n.t(
              'devise.two_factor_authentication.setup.label_totp_configuration_code',
            )}
          </Text>
          <Text>{otp_secret}</Text>
        </>
      );
    return (
      <div>
        {content}
        <div className={styles.codeInputContainer}>
          <PinField
            ref={this.codeInputRef}
            length={data?.digits || 6}
            validate="0123456789"
            className={styles.codeInput}
            onChange={this.onInputChange}
          />
        </div>
        <Errors errors={errors} />
      </div>
    );
  }

  renderPhoneView() {
    const {countryPhone, phoneNumber, errors, country, isFlagSelectVisible, is2FAMethodSelected} = this.state;
    const {i18n, isCancelHidden} = this.props;
    return (
      <div>
        <Text type="h5" customStyle={{marginBottom: 4}}>
          {i18n.t('devise.two_factor_authentication.setup.sms_title')}
        </Text>
        <Text color="#808080" tag="span">
          {i18n.t('devise.two_factor_authentication.setup.sms_subtitle')}
        </Text>
        <div className={styles.phoneWrapper}>
          {isFlagSelectVisible ?
            <FlagSelect
              className={styles.flagSelect}
              name="country"
              label=""
              variant="standard"
              onChange={this.onCountryChange}
              open={isFlagSelectVisible}
            /> : <TextField
            className={styles.inputText}
            name="phoneNumber"
            type="tel"
            label={i18n.t('devise.two_factor_authentication.mobile_number')}
            variant="standard"
            fullWidth
            onChange={this.onPhoneNumberChange}
            onFocus={this.clearErrors}
            autoFocus={true}
            value={phoneNumber}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={this.toggleFlagSelectVisible}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${country?.code?.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${country?.code?.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    <span>&nbsp;</span>
                    <ChevronDownIcon width="15" />
                  </IconButton>
                  <span>{countryPhone}</span>
                </InputAdornment>
              ),
            }}
          />}
        </div>


        <Errors errors={errors} />
        <div className={styles.buttons}>
          {!isCancelHidden && <ActionButton
            style={{flex: 1, marginRight: 8}}
            body={i18n.t('system_buttons.cancel')}
            onClick={this.onCancel}
          />}
          <ActionButton
            style={{flex: 1, marginLeft: 8}}
            active
            body={i18n.t(
              'devise.two_factor_authentication.setup.label_sms_configuration',
            )}
            disabled={phoneNumber === ''}
            onClick={this.onRegisterPhoneNumber}
          />
        </div>
        {!is2FAMethodSelected && <ActionButton
          justified
          style={{marginTop: 12}}
          body={i18n.t(
            'devise.two_factor_authentication.setup.configure_totp_instead',
          )}
          type="text"
          align="right"
          onClick={this.onUseAuthenticator}
        />}
      </div>
    );
  }

  render() {
    return (
      <div className={styles.popup} ref={this.wrapperRef}>
        {this.renderCurrentStep()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  credentials: getLoginCredentials(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openPopup,
      closePopup,
      sendOtpCode,
      login,
      sendOtpPhoneNumber,
      resendCode,
      startAuthenticatorAppFlow,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslator(TwoFactorVerification));
