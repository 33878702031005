const urls = {
  auth: {
    login: ({email} = {}) => `/users/sign_in${email ? `?email=${email}` : ''}`,
    signup: () => '/users/sign_up',
    register: () => '/member/sign_up',
    forgotPassword: (reset = false) =>
      `/users/password/new${reset ? '?reset=true' : ''}`,
    resetPassword: () => '/users/password/edit',
    sendConfirmationInstructions: () => '/users/confirmation/new',
    sso_signup: () => '/sso_signup/:id',
    onboarding: () => '/onboarding',
  },
};

export const {auth} = urls;
export default urls;
