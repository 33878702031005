import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import BaseApp from '@shared/app/BaseApp';
import reducers from './reducers';
import I18nContext from '@shared/i18n/context';

import Popup from '@shared/popups/Popup';
import PageWrapper from '@shared/layout/PageWrapper';
import routes from './routes';

import baseStyles from '@shared/styles/base.sass';
import globalStyles from '@shared/styles/global.sass';

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.

class AuthApp extends BaseApp {
  constructor(props) {
    super(props, reducers);
  }

  render() {
    return (
      <Provider store={this.store}>
        <I18nContext.Provider value={this.i18n}>
          <Router basename="/">
            <PageWrapper routes={routes} />
          </Router>
          <Popup />
        </I18nContext.Provider>
        <ToastContainer />
      </Provider>
    );
  }
}

export default AuthApp;
