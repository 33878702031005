import {createSelector} from 'reselect';
import {REGISTRATION_SOURCES_THEMES} from '../../whatsapp/shared/helpers/constants';
import {createSelectorFromKey} from '../utilities/selectors';

const getSession = (state) => state.session;
const createRootSelector = (key) => createSelectorFromKey(getSession, key);

export const getLoginCredentials = createRootSelector('loginCredentials');

export const getSiteName = createRootSelector('site_name');
export const getDomainName = createRootSelector('domain_name');
export const getCurrentFacet = createRootSelector('currentFacet');
export const getRegistrationSource = createRootSelector('registrationSource');

export const isBbva = createRootSelector('is_bbva');
export const getCurrentMember = createRootSelector('currentMember');

const createCurrentMemberSelector = (key) =>
  createSelectorFromKey(getCurrentMember, key);

export const getCurrentMemberId = createCurrentMemberSelector('id');

export const getCurrentMemberServiceId = getCurrentMemberId;

export const isCurrentMember = (id, state) =>
  createSelector(
    getCurrentMemberId,
    (currentMemberId) => currentMemberId === id,
  )(state);

export const isAdmin = createCurrentMemberSelector('is_admin');
export const isGlobalAdmin = createCurrentMemberSelector('is_global_admin');
export const isPartnerAdmin = createCurrentMemberSelector('is_partner_admin');
export const getPartnership = createCurrentMemberSelector('partnership');
export const isExpert = createCurrentMemberSelector('expert');

export const hasPartnership = createSelector(
  getPartnership,
  (partnership) => !!partnership,
);

export const getAlgoliaAppId = createCurrentMemberSelector(
  'algolia_search_app_id',
);

export const getAlgoliaApiKey = createCurrentMemberSelector(
  'algoliasearch_api_key',
);

export const getCurrentMemberSubs =
  createCurrentMemberSelector('subscriptions');

export const getCurrentMemberSubForSubscribableByType = (
  subscribable,
  notificationType,
  state,
) =>
  createSelector(getCurrentMemberSubs, (subs) =>
    subs.find(
      (sub) =>
        sub.subscribable_id === subscribable.id &&
        sub.subscribable_type === subscribable.type &&
        sub.notification_type === notificationType,
    ),
  )(state);

export const getCurrentMemberSubsForSubscribable = (subscribable, state) =>
  createSelector(
    () =>
      getCurrentMemberSubForSubscribableByType(subscribable, 'email', state),
    () =>
      getCurrentMemberSubForSubscribableByType(subscribable, 'mobile', state),
    (email, mobile) => ({email, mobile}),
  )(state);

export const getRegistrationSourceTheme = (state) =>
  createSelector(
    getRegistrationSource,
    (registrationSource) =>
      REGISTRATION_SOURCES_THEMES[registrationSource?.toLowerCase()],
  )(state);

export const getIsSwiftbizRegistrationSource = (state) =>
  createSelector(
    getRegistrationSource,
    (registrationSource) =>
      REGISTRATION_SOURCES_THEMES.swiftbiz ===
      registrationSource?.toLowerCase(),
  )(state);
