import * as actions from './actions';

const defaultOptions = {
  cancelable: true,
  closeOnOutsideClick: true,
  closeOnEscape: true,
  afterClose: () => {},
};

const initialState = {
  component: null,
  options: defaultOptions,
  fullScreenComponent: null,
  fullScreenOptions: defaultOptions,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case actions.OPEN_POPUP:
      return {
        ...state,
        component: action.component,
        options: {
          ...state.options,
          ...action.options,
        },
      };

    case actions.CLOSE_POPUP:
      state.options.afterClose();

      return {
        ...state,
        component: null,
        options: defaultOptions,
      };

    case actions.OPTIONS_UPDATED:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options,
        },
      };
    case actions.OPEN_FULLSCREEN_POPUP:
      return {
        ...state,
        fullScreenComponent: action.fullScreenComponent,
        fullScreenOptions: {
          ...state.fullScreenOptions,
          ...action.fullScreenOptions,
        },
      };

    case actions.CLOSE_FULLSCREEN_POPUP:
      state.fullScreenOptions.afterClose();

      return {
        ...state,
        fullScreenComponent: null,
        fullScreenOptions: defaultOptions,
      };

    case actions.FULLSCREEN_OPTIONS_UPDATED:
      return {
        ...state,
        fullScreenOptions: {
          ...state.fullScreenOptions,
          ...action.fullScreenOptions,
        },
      };

    default:
      return state;
  }
};

export default reducers;
