import React from 'react';
import PropTypes from 'prop-types';
import styles from './Toast.module.sass';

const Toast = ({
  message,
  buttonText,
  onButtonClick,
  closeOnButtonClick,
  closeToast,
}) => {
  const onClick = () => {
    onButtonClick();
    closeOnButtonClick && closeToast();
  };

  return (
    <div className={styles.container}>
      <span className={styles.message}>{message}</span>
      {buttonText && (
        <button type="button" className={styles.toastButton} onClick={onButtonClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default Toast;

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  closeOnButtonClick: PropTypes.bool,
};

Toast.defaultProps = {
  onButtonClick: () => {},
  closeOnButtonClick: true,
};
