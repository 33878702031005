class HttpError extends Error {
  constructor(code, message, response, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }

    this.code = code;
    this.message = message;
    this.response = response;
  }

  async error() {
    try {
      const error = await this.response.json();
      return error.error;
    } catch (err) {
      return this.message;
    }
  }
}

export default HttpError;
