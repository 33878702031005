import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import {withTranslator} from '@shared/components/wrappers';
import {getSiteName, getDomainName, getCurrentMember} from '@shared/session/selectors';
import urls from '@frontend/app/urls';
import {login, sendOtpPhoneNumber} from '@shared/session/actions';
import {openPopup} from '@shared/popups/actions';
import {post} from '@shared/services/api';
import Onboarding from './Onboarding';
import Title from '../components/Title';
import Logo from '@shared/components/Logo';
import TwoFactorVerification from '../popups/TwoFactorVerification';
import Input from '@shared/components/Input';
import ActionButton from '@shared/components/ActionButton';
import Errors from '@shared/components/Errors';
import toast from '@shared/helpers/toast';
import PasswordValidator from '../components/PasswordValidator';
import {withCta} from './CtaWrapper';

import styles from './Login.module.sass';

const initialState = {
  ssoToken: '',
  full_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone_number: '',
  showOnboarding: false,
  showPasswordValidator: false,
  errors: {
    email: [],
    phone_number: [],
    password: [],
    confirmPassword: [],
  },
  termsAccepted: false,
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  constraints = {
    email: {
      presence: {
        allowEmpty: false,
      },
      email: {
        message: (a,b,c,d, opts) => opts.i18n.t('auth.validation.email')
      },
    },
    password: {
      presence: {
        allowEmpty: false,
        message: (a,b,c,d, opts) => opts.i18n.t('auth.validation.password.presence')
      },
      length: {
        minimum: 8,
        maximum: 128,
        message: (a,b,c,d, opts) => opts.i18n.t('auth.validation.password.length')
      },
    },
    confirmPassword: {
      equality: {
        attribute: 'password',
        message: (a,b,c,d, opts) => opts.i18n.t('auth.validation.password.equality')
      },
    },
  }

  componentDidMount() {
    const {member} = this.props;
    const ssoToken = window.location.pathname.split('sso_signup/')[1] || '';

    this.setState({
      ssoToken,
      email: member?.email ?? '',
    });
  }

  onInputChange = (value, event) => {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: value,
    });
    this.clearError(name);
  }

  onInputBlur = (input, event) => {
    const target = event.target;
    const name = target.name;
    const errors = this.validateForm();
    let newState = {};

    if (name === 'password') {
      newState.showPasswordValidator = false;
    }

    const newValue = errors?.[name] ?? [];
    newState.errors = {
      ...this.state.errors,
      [name]: newValue,
    };
    if (name === 'password' && !errors?.confirmPassword) {
      newState.errors.confirmPassword = [];
    }

    this.setState(newState);
  }

  onPasswordFocus = () => {
    this.setState({
      showPasswordValidator: true,
    });
  }

  onRegister = async () => {
    const errors = this.validateForm();
    if (errors) {
      return this.handleValidationError(errors);
    } else {
      this.clearError();
    }
    const {i18n, member, login} = this.props;
    const {ssoToken, full_name, email, password, confirmPassword, phone_number} = this.state;
    const url = ssoToken !== '' ? urls.frontend.sso_signup : urls.api.register;
    let user = {
      email,
      password,
      phone_number,
    }
    if (ssoToken) {
      user = { ...user, full_name };
    }
    try {
      const postResult = await post(url(), {
        token: ssoToken,
        user:  user,
      }, {
        authorizing: true,
      });
      if ( !ssoToken ) {
        const session = await login(email, password);
        this.onRegistrationComplete(session);
      } else {
        initialState.ssoToken = ssoToken;
        this.setState(initialState);
        toast.info(postResult.toast);
      }
    } catch(err) {
      this.handleServerError(err.message);
      console.log('error registering user', err);
    }
  }

  async handleServerError(error) {
    const parsedError = error.error;
    if (parsedError?.otp_state) {
      switch(parsedError.otp_state) {
        case 'missing':
          this.startTwoFactorVerification(parsedError, false);
          break;
        case 'required':
          this.startTwoFactorVerification(parsedError, true);
          break;
        case 'too_many_attempts':
          this.handleError(parsedError);
          break;
        default:
          console.log('unhandled otp state');
      }
    } else {
      this.handleError(parsedError);
    }
  }

  async startTwoFactorVerification(otpData, phoneVerified) {
    const {openPopup} = this.props;
    openPopup(
      <TwoFactorVerification
        data={otpData}
        onAuthSuccess={this.onRegistrationComplete}
        phoneVerified={phoneVerified}
      />
    );
  }

  onRegistrationComplete = ({first_time}) => {
    toast.hideAll();

    if (first_time) {
      this.setState({
        showOnboarding: true,
      })
    } else {
      window.location.assign('/');
    }
  }

  handleError(parsedError) {
    let errors = parsedError?.errors;
    if (!errors) {
      const {i18n} = this.props;
      errors = [i18n.t('errors.messages.generic')];
    }
    this.setState({errors} );
 }

  handleValidationError(error) {
    this.setState({errors: error});
  }

  isFormReady = () => {
    const formInvalid = this.validateForm();
    return !formInvalid;
  }

  validateForm() {
    const {email, password, confirmPassword} = this.state;
    return validate({email, password, confirmPassword}, this.constraints);
  }

  clearError(type) {
    if (!type) {
      this.setState(initialState.errors);
    } else {
      let newErrors = {
        ...this.state.errors,
        [type]: [],
      };
      this.setState({
        errors: newErrors,
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.isFormReady()) {
      this.onRegister();
    }
  }

  render() {
    const {siteName, member, i18n} = this.props;
    const {
      full_name,
      email,
      password,
      confirmPassword,
      phone_number,
      errors,
      showPasswordValidator,
      showOnboarding,
      ssoToken
    } = this.state;

    if (showOnboarding) {
      return <Onboarding />;
    }

    const formReady = this.isFormReady();

    return (
      <div className={styles.container}>
        <Logo />
        <Title text={i18n.t('auth.registration.title', {site: siteName})} />
        <form className={styles.form} onSubmit={this.onSubmit}>
          { ssoToken &&
            (
              <Input
                value={full_name}
                name="full_name"
                placeholder={i18n.t('auth.fields.full_name')}
                error={errors?.full_name?.length > 0}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
              />
            )
          }
          <Input
            value={email}
            type="email"
            name="email"
            placeholder={i18n.t('auth.fields.email')}
            error={errors.email?.length > 0}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
          />
          <Input value={password}
            type="password"
            name="password"
            placeholder={i18n.t('auth.fields.password')}
            autoComplete="new-password"
            error={errors.password?.length > 0}
            onChange={this.onInputChange}
            onFocus={this.onPasswordFocus}
            onBlur={this.onInputBlur}
          />
          {showPasswordValidator && <PasswordValidator password={password} />}
          <Input
            value={confirmPassword}
            type="password"
            name="confirmPassword"
            placeholder={i18n.t('auth.fields.confirm_password')}
            autoComplete="new-password"
            error={errors.confirmPassword?.length > 0}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
          />
          <Errors errors={errors} />

          <ActionButton
            justified
            active
            type="primary"
            body={i18n.t('auth.registration.button')}
            submit
            disabled={!formReady}
            style={{marginTop: '12px'}}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  siteName: getSiteName(state),
  domainName: getDomainName(state),
  member: getCurrentMember(state),
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({ openPopup, login, sendOtpPhoneNumber }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withCta(withTranslator(Register)));
