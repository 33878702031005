import React from 'react';
import {useSelector} from 'react-redux';
import {isAdmin} from '@shared/session/selectors';
import NotFound404Page from '@shared/components/NotFound404Page';

const ifAdmin = (OtherComponent = NotFound404Page) => (Component) => (props) => {
  if (useSelector(isAdmin)) {
    return <Component {...props} />;
  } else {
    return <OtherComponent {...props} />;
  }
};

export default ifAdmin;
