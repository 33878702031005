import {createSelector} from 'reselect';
import {createSelectorFromKey} from '@shared/utilities/selectors';

import {isLeia, getPartner as getLeiaPartner} from '@shared/leia/selectors';

const getRoot = (state) => state.registration;
const createRootSelector = (key) => createSelectorFromKey(getRoot, key);

export const getRegistration = getRoot;

export const hasFeature = (feature) =>
  createSelector(getRegistration, (registration) =>
    registration?.features?.includes(feature),
  );

export const getTemplateIcons = createRootSelector('template_icons');

export const getLogoUrl = createRootSelector('logo_url');
export const getHeaderColor = createRootSelector('header_color');

export const getAutomaticMessages = createRootSelector('automatic_messages');

export const getMeetingDuration = createRootSelector('meeting_duration');

export const getStoreId = createRootSelector('store_id');

const DEFAULT_BRAIDIO_LOGO_SRC = '/images/frontend/braidio-logo.svg';
const DEFAULT_LEIA_LOGO_SRC = '/images/frontend/braidio-leia-logo.svg';

export const getLogoSrc = (state) => {
  const registrationLogoUrl = getLogoUrl(state);
  if (registrationLogoUrl) return registrationLogoUrl;

  const leiaPartner = getLeiaPartner(state);
  if (leiaPartner) return leiaPartner.image_path;

  if (isLeia(state)) return DEFAULT_LEIA_LOGO_SRC;
  else return DEFAULT_BRAIDIO_LOGO_SRC;
};
