import urls from '../../admin/app/urls';
import {patch} from '../services/api';

const ACTION_PREFIX = 'SETTINGS';
export const PATCH_AUTOMATIC_MESSAGES = `${ACTION_PREFIX}.PATCH_AUTOMATIC_MESSAGES.REQUESTED`;
export const PATCH_AUTOMATIC_MESSAGES_SUCCEEDED = `${ACTION_PREFIX}.PATCH_AUTOMATIC_MESSAGES.SUCCEEDED`;
export const PATCH_AUTOMATIC_MESSAGES_FAILED = `${ACTION_PREFIX}.PATCH_AUTOMATIC_MESSAGES.FAILED`;

const requestedActionCreator = (ACTION, optimistic) => ({
  type: ACTION,
  error: null,
  optimistic,
});

const patchAutomaticMessagesSucceeded = (automaticMessages) => ({
  type: PATCH_AUTOMATIC_MESSAGES_SUCCEEDED,
  automaticMessages,
  error: null,
});
const patchAutomaticMessagesFailed = (error, key, backup) => ({
  type: PATCH_AUTOMATIC_MESSAGES_FAILED,
  error,
  key,
  backup,
});

export const patchAutomaticMessages =
  ({enabled, key, text}) =>
  async (dispatch) => {
    dispatch(requestedActionCreator(PATCH_AUTOMATIC_MESSAGES, {key, enabled}));

    const payload = {
      [key]: {
        text,
        enabled,
      },
    };

    try {
      const {automatic_messages: automaticMessages} =
        (await patch(urls.api.admin.settingsSite(), {
          registration: {automatic_messages: payload},
          no_redirect: true,
        })) ?? {};

      return dispatch(patchAutomaticMessagesSucceeded(automaticMessages));
    } catch (error) {
      return dispatch(patchAutomaticMessagesFailed(error, key, !enabled));
    }
  };
