import {buildUrl} from '@shared/app/urls';

const apiUrl      = '/api/admin';
const adminPath   = '/admin';

const urls = {
  pages: {
    admin: {
      dashboard:               ()   => `${adminPath}/settings/dashboard`,
      workstreams:             ()   => `${adminPath}/workstreams`,
      newWorkstream:           ()   => `${adminPath}/workstreams/new`,
      editWorkstream:          (id) => `${adminPath}/workstreams/${id}/edit`,
      subdomains:              ()   => `${adminPath}/subdomains`,
      newSubdomain:            ()   => `${adminPath}/subdomains/new`,
      editSubdomain:           (id) => `${adminPath}/subdomains/${id}/edit`,
      audits:                  ()   => `${adminPath}/audits`,
      audit:                   (id) => `${adminPath}/audits/${id}`,
      reportings:              ()   => `${adminPath}/reportings`,
      registrations:           ()   => `${adminPath}/registrations`,
      newRegistration:         ()   => `${adminPath}/registrations/new`,
      editRegistration:        (id) => `${adminPath}/registrations/${id}/edit`,
    },

    // Not under react router so we need to use full paths here
    legacy_admin: {
      connections:             ()   => '/admin/connections',
    }
  },

  api: {
    admin: {
      dashboardCourses:          ()     => `${apiUrl}/dashboard_courses`,
      connections:               ()     => `${apiUrl}/connections`,
      externalObjects:           ()     => `${apiUrl}/external_objects`,
      courses:                   ()     => `${apiUrl}/courses`,
      courseFolders:             ()     => `${apiUrl}/course_folders`,
      courseAnalytics:           (id)   => `${apiUrl}/courses/${(id)}/analytics`,
      courseIndividualResponses: (id)   => `${apiUrl}/individual_responses/${id}`,
      conversations:             ()     => `${apiUrl}/conversations`,
      conversation:              (id)   => `${apiUrl}/conversations/${id}`,
      domainReports:             ()     => `${apiUrl}/domain_reports`,
      domainReport:              (id)   => `${apiUrl}/domain_reports/${id}`,
      participants:              (conversationId) => `${apiUrl}/conversations/${conversationId}/participants`,
      participant:               (conversationId, id) => `${apiUrl}/conversations/${conversationId}/participants/${id}`,
      questions:                 ()     => `${apiUrl}/questions`,
      roles:                     ()     => `${apiUrl}/roles`,
      members:                   ()     => `${apiUrl}/members`,
      workstreams:               (page) => buildUrl(apiUrl, '/workstreams', {page}),
      workstream:                (id)   => `${apiUrl}/workstreams/${id}`,
      attachFileToWorkstream:    (id)   => `${apiUrl}/workstreams/${id}/attach_file`,
      subdomains:                (page) => buildUrl(apiUrl, '/subdomains', {page}),
      subdomainsGlobal:          (page) => buildUrl(apiUrl, '/subdomains', {admin: true, page}),
      subdomain:                 (id)   => `${apiUrl}/subdomains/${id}`,
      audits:                    (page, {remote_address, city, region, country, suspicious_category}) => buildUrl(apiUrl, '/audits', {page, remote_address, city, region, country, suspicious_category}),
      auditsOccurrences:         ({remote_address, city, region, country, suspicious_category})       => buildUrl(apiUrl, '/audits_occurrences', {remote_address, city, region, country, suspicious_category}),
      audit:                     (id)   => `${apiUrl}/audits/${id}`,
      revisions:                 (auditId, page) => buildUrl(apiUrl, `/audits/${auditId}/revisions`, {page}),
      reportables:               (page) => buildUrl(apiUrl, '/reportables', {page}),
      reportable:                (reportableId, reportableType) => `${apiUrl}/reportables/${reportableId}?reportable_type=${reportableType}`,
      settingsSite:              () => '/admin/settings/site',
    },
  },

};

export default urls;
