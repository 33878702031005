import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslator} from '@shared/hooks';
import {getRegistration} from '@shared/registration/selectors';

import styles from './CtaWrapper.module.sass';

const CtaWrapper = ({children}) => {
  const i18n = useTranslator('main_nav');
  const registration = useSelector(getRegistration);

  return (
    <>
      <div className={styles.ctaContainer} style={registration?.landing_url ? {backgroundColor: registration.header_color} : {}}>
        {registration?.landing_url ? <img className={styles.customLandingImage} src={registration.landing_url} /> : <img src={'/images/frontend/onboarding-simplify.svg'} />}
        {registration?.custom_greeting ? <div className={styles.customGreeting}>{registration.custom_greeting}</div> : <div>{i18n.t('simplify')}</div>}
      </div>
      <div className={styles.ctaContent}>
        {children}
      </div>
    </>
  );
}

export default CtaWrapper;

export const withCta = (Component) => (props) =>
  <CtaWrapper><Component {...props}/></CtaWrapper>;

