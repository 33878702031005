import React from 'react';
import {useSelector} from 'react-redux';
import {isAdmin, isExpert} from '@shared/session/selectors';
import NotFound404Page from '@shared/components/NotFound404Page';

const ifExpert = (OtherComponent = NotFound404Page) => (Component) => (props) => {
  if (useSelector(isExpert) || useSelector(isAdmin)) {
    return <Component {...props} />;
  } else {
    return <OtherComponent {...props} />;
  }
};

export default ifExpert;

