import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {useSelector} from 'react-redux';
import {useTranslator} from '@shared/hooks';

import {redirectWithMethod} from '@shared/utilities/browser';
import {hasFeature} from '@shared/registration/selectors';

import ActionButton from '@shared/components/ActionButton';

import IntuitIcon from '@shared/images/intuit.svg';
import SalesForceIcon from '@shared/images/salesforce.svg';
import OktaIcon from '@shared/images/okta.svg';

import styles from './LoginMoreOptions.module.sass';

const LoginMoreOptions = () => {
  const i18n = useTranslator();
  const hasOktaFeature = useSelector(hasFeature('okta'));

  const onIntuitSignIn = () => redirectWithMethod('/users/auth/intuit');
  const onSalesforceSignin = () => redirectWithMethod('/users/auth/salesforce');
  const onOktaSignin = () => redirectWithMethod('/users/auth/okta');

  return (
    <div className={styles.signInOptions}>
      <ActionButton
        justified
        body={() => {
          const icon = ReactDOMServer.renderToString(<IntuitIcon />);
          const label = i18n.t('auth.sign_in.with', {icon});
          return (
            <div
              className={styles.signInOption}
              dangerouslySetInnerHTML={{__html: label}}></div>
          );
        }}
        onClick={onIntuitSignIn}
      />
      <ActionButton
        justified
        body={() => {
          const icon = ReactDOMServer.renderToString(<SalesForceIcon />);
          const label = i18n.t('auth.sign_in.with', {icon});
          return (
            <div
              className={styles.signInOption}
              dangerouslySetInnerHTML={{__html: label}}></div>
          );
        }}
        onClick={onSalesforceSignin}
      />
      {hasOktaFeature && (
        <ActionButton
          justified
          body={() => {
            const icon = ReactDOMServer.renderToString(<OktaIcon />);
            const label = i18n.t('auth.sign_in.with', {icon});
            return (
              <div
                className={styles.signInOption}
                dangerouslySetInnerHTML={{__html: label}}></div>
            );
          }}
          onClick={onOktaSignin}
        />
      )}
    </div>
  );

};

export default LoginMoreOptions;
