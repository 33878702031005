import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactLoader from 'react-loader-spinner';
import styles from './Loader.module.sass';

function Loader({size, color, type, className}) {
  return (
    <div className={classNames(styles.container, className)}>
      <ReactLoader type={type} color={color} width={size} height={size} />
    </div>
  );
}

Loader.defaultProps = {
  size: 40,
  color: '#0088FD',
  type: 'TailSpin',
};

Loader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'Audio',
    'BallTriangle',
    'Bars',
    'Circles',
    'Grid',
    'Hearts',
    'Oval',
    'Puff',
    'Rings',
    'TailSpin',
    'ThreeDots',
    'Watch',
    'RevolvingDot',
    'Triangle',
    'Plane',
    'MutatingDots',
    'CradleLoader',
  ]),
};

export default Loader;
