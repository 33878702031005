export const redirectWithMethod = (path, {method = 'post', params} = {}) => {
  const form = document.createElement('form');
  form.action = path;
  form.method = method;
  form.style = 'display: none';

  if (params) {
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        const field = document.createElement('input');
        field.setAttribute('type', 'hidden');
        field.setAttribute('name', key);
        field.setAttribute('value', params[key]);

        form.appendChild(field);
      }
    }
  }

  const csrf = document.querySelector('meta[name=csrf-token]');
  if (csrf) {
    const field = document.createElement('input');
    field.setAttribute('type', 'hidden');
    field.setAttribute('name', 'authenticity_token');
    field.setAttribute('value', csrf.content);

    form.appendChild(field);
  }

  document.body.appendChild(form);
  form.submit();
};
