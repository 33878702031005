import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import typography from '@shared/utilities/typography';

const getStyleForType = (type = 'body') => {
  return typography.textStyles[type];
};

function Text({
  tag,
  type,
  color,
  children,
  customStyle,
  className,
  customClass,
  ...props
}) {
  const textColor = color || getStyleForType(type).color;

  className = classNames(className, customClass);

  return React.createElement(
    tag,
    {
      style: {
        ...getStyleForType(type),
        color: textColor,
        ...customStyle,
      },
      className,
      ...props,
    },
    children,
  );
}

Text.defaultProps = {
  tag: 'p',
  type: 'body',
  customStyle: {},
  customClass: '',
};

Text.propTypes = {
  tag: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  customClass: PropTypes.string,
};

export default Text;
