import {get} from '@shared/services/api';
import urls from '@shared/app/urls';

export const TECHNOLOGIES_FETCHED = 'TECHNOLOGIES_FETCHED';

export const technologiesFetched = technologies => ({
  type: TECHNOLOGIES_FETCHED,
  technologies,
});

export const fetchTechnologies = () => async dispatch => {
  try {
    const data = await get(urls.api.technologies());
    dispatch(technologiesFetched(data));
  } catch(err) {
    return Promise.reject(err);
  }
}
